import { styled } from "styled-components";


type propsType = {
    duration: number,
    time: string
}
export const ButtonNote = styled.button<propsType>`
width: 100%;
z-index: 2;
bottom: 0;
right: 0;
left: 0;
height: calc(${props => props.duration / 60 * 100 - 2}%);
top: calc(${props => (parseInt(props.time.split(":")[1]) / 60 * 100)}%);
background: rgb(178, 178, 178);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
border: none;
position: relative;
opacity: 0;
svg {
    height: 20px;
    width: 20px;
};
&:hover{
    opacity: 1;
}
`