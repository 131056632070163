import c from "./clientCard.module.css";
import plus from "../../assets/actions/plus.svg";
import phone from "../../assets/actions/phone.svg";
import cat from "../../assets/actions/cat.png";
import { v1 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { notesStateType } from "../../store/NotesReducer";
import { useEffect, useId, useRef, useState } from "react";
import { stateUserType, tariffType } from "../../store/UserReducer";
import addPhoto from "../../assets/add-photo.png";
import "../../styles/global/alice.css";
import {
  clientBalanceWindowAC,
  editClientWindowAC,
  editNoteWindowAC,
  ModalWindowStateType,
  remindWindowAC,
  selectClientAC,
  setDateAndTimeAC,
  showNewEntryAC,
  showQueueAC,
  showWaWindowAC,
} from "../../store/ModalWindowsReducer";
import time from "../../assets/actions/time.svg";
import {
  addClientCardPhotoTC,
  addClientCommentTC,
  changeClientAvatarTC,
  clientCardStateType,
  resetClientCardAC,
  setClientCardTC,
} from "../../store/ClientCardReducer";
import ring2 from "../../assets/ring2.svg";
import row from "../../assets/row.svg";
import { NewEntry } from "../../modalWindows/child/NewEntry";
import { sessionsType, setSessionsAC } from "../../store/SessionsReducer";
import { useParams } from "react-router-dom";
import { Works } from "./works/Works";
import { getReminderClientTC } from "../../store/RemindersReducers";
import { FlexBox } from "../../styles/components/FlexBox";
import { theme } from "../../styles/components/Theme.styled";
import { Text } from "../../styles/components/Text";
import { Box } from "../../styles/components/Box";
import { Icon } from "../../styles/components/Icon";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ClientComments } from "./ClientComments";
import { ClientVisits } from "./ClientVisits";
import { ClientQueue } from "./ClientQueue";
import { SuperButton } from "../../styles/components/SuperButton";
import { ClientRemindersStatus } from "../../apiTypes/root/reminders.type";
import { setSuccesMessageClientAvaAC } from "../../store/AppReducer";
import { clientsStateType } from "../../store/ClientsReducer";
import {
  teammateProSettingsType,
  teamMateViewType,
} from "../../apiTypes/root/userProPlus.type";
import { ClientAppeals } from "./ClientAppeals";
import { Avatar } from "../../styles/components/Avatar";
import { Grid } from "../../styles/components/Grid";
import { ClientReminders } from "./ClientReminders";

type actionsType = {
  id: string;
  title: string;
  img: string;
  disabled: boolean
  cb?: () => void;
};
type mobActionsType = {
  id: string,
  title: string,
  svgId: string,
  active: boolean,
  component: React.JSX.Element
}

type newPhotoFileType = {
  fileName: string;
  file: File;
  type: string;
};
const selectReminders = (state: AppRootState) => state.remindersState;
const selectAppState = (state: AppRootState) => state.appState;
const selectModalState = (state: AppRootState) => state.ModalWindowReducer;
const selectProPplus = (state: AppRootState) => state.userProPlusState;

export const ClientCard = () => {
  const fileReader = new FileReader();
  const clientFileRedaer = new FileReader();
  const formatter = new Intl.NumberFormat("ru", {
    style: "currency",
    currency: "rur",
  });
  
  const { id } = useParams();
  const appState = useSelector(selectAppState);
  const modalState = useSelector(selectModalState);
  const [photoLabel, showPhotoLabel] = useState(false);
  const [newPhotoURL, setNewPhotoURL] = useState("");
  const [newClientPhotoURL, setNewClientPhotoURL] = useState("");
  const [newClientPhotoFile, setNewClientPhotoFile] = useState<File | null>(
    null
  );
  const [newPhotoFile, setNewPhotoFile] = useState<newPhotoFileType | null>(
    null
  );
  const [teammate, setTeammate] = useState<null | teammateProSettingsType>(
    null
  );
  const userProPlusState = useSelector(selectProPplus);
  const dispatch: thunkDispatchRootType = useDispatch();
  const showClientPay = () =>
    dispatch(clientBalanceWindowAC({ value: true, client: clientCard }));
  const showQueue = () =>
    dispatch(showQueueAC({ value: true, client: clientCard }));

  const client = useSelector<AppRootState, clientCardStateType>(
    (state) => state.clientCard
  );
  const clientCard = client.client;

  const user = useSelector<AppRootState, stateUserType>((state) => state.user);
  const reminders = useSelector(selectReminders);
  const notes = useSelector<AppRootState, notesStateType[]>(
    (state) => state.notes
  );

  const phoneField: {
    id: string;
    title: string;
    value: string | undefined | null;
  }[] = [
    { id: v1(), title: "Тел:", value: clientCard.phone && clientCard.phone },
    { id: v1(), title: "Скидка:", value: "" },
    //{ id: v1(), title: "Номер карты:" },
    {
      id: v1(),
      title: "День рождения: ",
      value: JSON.stringify(clientCard.birthday).substring(1, 11),
    },
  ];
  const showEditClient = () => {
    dispatch(editClientWindowAC({ value: true, client: clientCard }));
  };
  const showNewEntry = () => {
    dispatch(setDateAndTimeAC("", "", ""));
    dispatch(showNewEntryAC({ value: true, fromQueue: false }));
    dispatch(selectClientAC(clientCard));
  };
  const showWaSendWindow = () => {
    const {
      id,
      userId,
      name,
      surname,
      patronymic,
      phone,
      photoURL,
      birthday,
      gender,
      agreement,
      comments,
      balance,
      addedDate,
    } = clientCard;
    // dispatch(showWaWindowAC({value: true, client: {id,owner: userId,name,surname,patronymic,phone,photoURL,birthday,gender,agreement,comments,balance,addedDate,userId}}))
  };

  const actionsArray: actionsType[] = [
    // { id: v1(), title: "Отправить SMS", img: shape },
    // { id: v1(), title: "Документы", img: document },
    { id: v1(), disabled: false, title: "В очередь", img: "users-more", cb: showQueue },
    { id: v1(), disabled: false, title: "Записать на прием", img: "pinpaper", cb: showNewEntry },
    { id: v1(), disabled: (user.user?.tariff === tariffType.SuperPRO && client.client.userId !== user.user?.id && (!teammate?.clientRights.canEditAllClients) && (!teammate?.isOwner)), title: "Редактировать", img: "edit-4", cb: showEditClient },
    { id: v1(), disabled: (user.user?.tariff === tariffType.SuperPRO && client.client.userId !== user.user?.id && (!teammate?.clientRights.canTopUpBalanceTeammatesClients) && (!teammate?.isOwner)), title: "Пополнить счет", img: "card", cb: showClientPay },
    { id: v1(), disabled: false, title: "Написать", img: "WA", cb: showWaSendWindow },
  ];
  
  const initActionMobile:Array<mobActionsType> = [
    {id: useId(), title: "Действие", svgId: "cursor-default", active: false, component:
     <Grid className={c.mobBtnContainer} gap={5} width="100%"  height="auto" columns="1fr 1fr">
      {actionsArray.map((el,i) => (
                  <button className={c.mobBtn}   onClick={el.cb} key={el.id}>
                    <span>{el.title}</span>
                    <Icon stroke="rgb(77, 113, 129)" fill={(i+1) === actionsArray.length ? "green" : "none"} svgID={el.img} width={24} height={22}/>
                  </button>
                ))}
    </Grid>},
    {id: useId(), title: "Комментарии", svgId: "message-circle-lines-mob", active: false, component: <ClientComments clientId={clientCard.id} comments={clientCard.comments}/>},
    {id: useId(), title: "Снимки", svgId: "multimedia", active: false, component: <></>},
    {id: useId(), title: "Обращения", svgId: "ring3", active: false, component: <ClientAppeals clientId={clientCard.id} showNewEntry={showNewEntry}/>},
    {id: useId(), title: "Визиты", svgId: "visit", active: false, component: <ClientVisits clientId={clientCard.id} showNewEntry={showNewEntry}/>},
    {id: useId(), title: "Очередь", svgId: "queue2", active: false, component: <ClientQueue clientId={clientCard.id} showQueue={showQueue}/>},
    {id: useId(), title: "Напоминания", svgId: "ring4", active: false, component: <ClientReminders clientId={clientCard.id}/>
    
    },
    {id: useId(), title: "Работы", svgId: "folder", active: false, component: <></>},
  ]
  const [mobBtnsActions, setMobTnsActions] = useState<Array<mobActionsType>>(initActionMobile)
  const addRemindWindow = () => {
    dispatch(remindWindowAC({ value: true, client: clientCard }));
  };
  const photoHandler = (file: null | File) => {
    if (file) {
      fileReader.readAsDataURL(file);
      setNewPhotoFile({ file, fileName: file.name, type: file.type });
    }
  };
  fileReader.onloadend = () => {
    if (fileReader.result) {
      setNewPhotoURL(fileReader.result.toString());
    }
  };
  const clientAvaHandler = (file: File | null) => {
    if (file) {
      clientFileRedaer.readAsDataURL(file);
      setNewClientPhotoFile(file);
      return;
    } else {
      return;
    }
  };
  const resetClientAvaChanges = () => {
    setNewClientPhotoFile(null);
    setNewClientPhotoURL("");
  };
  clientFileRedaer.onloadend = () => {
    if (clientFileRedaer.result) {
      setNewClientPhotoURL(clientFileRedaer.result.toString());
    }
  };
  const uploadClientPhoto = () => {
    if (newPhotoFile) {
      const { file, fileName, type } = newPhotoFile;
      dispatch(addClientCardPhotoTC(file, type, fileName, clientCard.id));
      showPhotoLabel(false);
    }
  };
  const changeAva = () => {
    if (!newClientPhotoFile || !id) {
      return;
    } else {
      dispatch(changeClientAvatarTC(newClientPhotoFile, id));
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleBtnActions = () => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };
  const mobHandler = (id: string) => {
    setMobTnsActions(mobBtnsActions.map(el => el.id === id ? ({...el,active: !el.active}) : el))
  }
  // useEffect(() => {
  //     // dispatch(getNotesTC(user.user?._id as string))
  //     dispatch(getPayTC(clientCard.id))
  //     // dispatch(getQueueTC(clientCard.id))
  //     dispatch(getRemindTC(clientCard.id))
  // }, [JSON.stringify(notes)])
  // useEffect(() => {
  //     const visits = notes.filter(n => n.phone === clientCard.phone && new Date(n.date.split(".").reverse().join("-")) < new Date())
  //     setVisits(visits.length || 0)
  //     const currentVisits = notes.sort((a, b) => a.date && b.date && new Date(a.date.split(".").reverse().join("-")) < new Date(b.date.split(".").reverse().join("-")) ? 1 : -1).slice(0, 3)
  //     setLastVisits(currentVisits);

  // }, [notes.length])
  // useEffect( () => {
  //     const counter = remindersState.filter( el => el.isActive === true).length
  //     setCount(counter)
  // }, [JSON.stringify(remindersState)])
  const handleDragStart = (e: any) => e.preventDefault();

  const responsive = {
    0: { items: 1 },
    560: { items: 2 },
    1024: { items: 3 },
  };

  const listInnerRef = useRef<any>(null);
  const onScroll = () => {
    //@ts-ignore
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      return new Promise((res, rej) => {
        setTimeout(() => res("data"), 500);
      }).then((res) => console.log("res", res));
    }
  };
  
  useEffect(() => {
    if (id) {
      dispatch(setClientCardTC(id));
      dispatch(getReminderClientTC(id));
    }
    return () => {
      dispatch(resetClientCardAC());
    };
  }, []);
  useEffect(() => {
    if (!photoLabel) {
      setNewPhotoURL("");
      setNewPhotoFile(null);
    }
  }, [photoLabel]);
  useEffect(() => {
    if (client.client.photoURL && appState.successMessageAvatarChange) {
      setNewClientPhotoFile(null);
      setNewClientPhotoURL("");
      setTimeout(() => {
        dispatch(setSuccesMessageClientAvaAC(""));
      }, 2500);
    }
  }, [client.client.photoURL, appState.successMessageAvatarChange]);
  useEffect(() => {
    if (modalState.editNoteState.value) {
      dispatch(editNoteWindowAC({ value: false, note: null }));
    }
  }, []);
  useEffect(() => {
    if (userProPlusState.teammates.teammates.length) {
      const me = userProPlusState.teammates.teammates.find(
        (t) => t.user.userId === user.user?.id
      );
      if (me) {
        setTeammate(me);
      }
    }
  }, [userProPlusState.teammates.teammates.length]);
  return (
    <div
      style={{ width: "95%", height: "100vh", overflowY: "scroll" }}
      className={c.wrapper}
    >
      <div className={c.title}>
        <span>Карточка клиента</span>
      </div>
      <div className={c.info} style={{ height: showPopup ? "550px" : "350px" }}>
        <div className={c.name}>
          <div className={c.photo}>
            <div className={c.ava}>
              <img
                src={
                  newClientPhotoURL ? newClientPhotoURL : clientCard.photoURL
                }
                alt=""
              />
              <SuperButton className={c.inputFile} as={"label"} varinat="photo">
                <Icon svgID="camera" width={24} height={24} />
                <input
                  onChange={(e) =>
                    e.currentTarget.files &&
                    clientAvaHandler(e.currentTarget.files[0])
                  }
                  type="file"
                />
              </SuperButton>
            </div>
            <div className={c.nameSurname}>
              <span className={c.n}>{clientCard.surname}</span>
              <div className={c.np}>
                <span>{clientCard.name}</span>
                <span>{clientCard.patronymic}</span>
              </div>
              {newClientPhotoFile && newClientPhotoURL && (
                <FlexBox direction="row" gap={5}>
                  <button onClick={resetClientAvaChanges}>Отменить</button>
                  <button onClick={changeAva}>Сохранить</button>
                </FlexBox>
              )}
              {appState.successMessageAvatarChange && (
                <Text color="#fff" size={11} font="mont">
                  {appState.successMessageAvatarChange}
                </Text>
              )}
            </div>
          </div>
          <div className={c.balanceContainer}>
            <div className={`${c.item} ${c.visits}`}>
              <span>Визитов</span>
              <Text color={theme.colors.fonts.main} font="Inter" size={18}>
                {clientCard.visitCount}
              </Text>
            </div>

            <div className={`${c.item} ${c.spent}`}>
              <span>Потрачено</span>
              {client.client.userId === user.user?.id ||
              teammate?.clientRights.canSeeBalanceTeammatesClients ? (
                <Text color={theme.colors.fonts.main} font="Inter" size={18}>
                {formatter.format(client.client.spentMoneyCount)}
              </Text>
              ) : (
                <Text
                  size={12}
                  font="mont"
                  color={theme.colors.fonts.main}
                >{`*******`}</Text>
              )}
              
            </div>
            <div className={`${c.item} ${c.balance}`}>
              <span>Баланс</span>
              {client.client.userId === user.user?.id ||
              teammate?.clientRights.canSeeBalanceTeammatesClients ? (
                <Text color={theme.colors.fonts.main} font="Inter" size={18}>
                {formatter.format(client.client.balance)}
              </Text>
              ) : (
                <Text
                  size={12}
                  font="mont"
                  color={theme.colors.fonts.main}
                >{`*******`}</Text>
              )}
             
            </div>
            <div className={c.actionClient}>
              <button onClick={handleBtnActions}>Действия с клиентом</button>
            </div>
            {showPopup && (
              <div className={c.action2}>
                {actionsArray.map((el) => (
                  <button disabled  onClick={el.cb} key={el.id}>
                    <span>{el.title}</span>
                    <img src={el.img} alt="" />
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={c.phone}>
          {phoneField.map((el) => (
            <div key={el.id}>
              <span>{el.title}</span>
              <span>{el.value}</span>
            </div>
          ))}
        </div>
        <div className={c.action}>
          {actionsArray.map((el) => (
            <button disabled = {el.disabled} onClick={el.cb && el.cb} key={el.id}>
              <Text font="mont" size={11} color={theme.colors.fonts.secondary}>
                {el.title}
              </Text>
              <Icon
                fill={el.img === "WA" ? "green" : "#fff"}
                stroke={theme.colors.main}
                svgID={el.img}
                width={24}
                height={24}
              />
            </button>
          ))}
        </div>
      </div>
      <div className={c.mobileInfo}>
        <FlexBox align="center" justify="space-between" direction="row" width="100%" height="84px" padding="10px" >
          <FlexBox style={{position:"relative"}}  justify="center" align="center"   height="100%" direction="column"> 
           
            <SuperButton as={"label"} style={{border:"2px solid"}} className={c.inputFile}  varinat="photo">
                  {/* <Icon svgID="camera" width={24} height={24} /> */}
                  <input
                  onChange={(e) =>
                    e.currentTarget.files &&
                    clientAvaHandler(e.currentTarget.files[0])
                  }
                  type="file"
                />
               </SuperButton>
              <Avatar size="extralarge" src={
                  newClientPhotoURL ? newClientPhotoURL : clientCard.photoURL
                } />
                 
            
         
             
          </FlexBox>
        
          <FlexBox direction="column" height="100%">
            <SuperButton onClick={showEditClient} varinat="fake">
              <Text style={{fontSize: "14px"}} font="mont" size={14} color={theme.colors.fonts.main} weight={700}>ред.</Text>
            </SuperButton>
            <Text size={12} font="mont" color={theme.colors.fonts.main}>{`Добален (${ new Date(client.client.addedDate).toLocaleDateString()})`}</Text>
            <Text size={12} font="mont" color={theme.colors.fonts.main}>{`День рождения: ${ new Date(client.client.birthday as string).toLocaleDateString()}`}</Text>
          </FlexBox>
        </FlexBox>
        {newClientPhotoFile && newClientPhotoURL && (
                <FlexBox marginTop="10px" height="25px" border="1px solid red" direction="row" gap={5}>
                  <SuperButton type="button" varinat="fake" onClick={(e) => {e.preventDefault(); resetClientAvaChanges()}}>
                    
                    <Text size={16} font="mont" color={theme.colors.fonts.main}>Отменить</Text>

                    </SuperButton>
                  <SuperButton varinat="fake" onClick={(e) => {e.preventDefault(); changeAva()} }>
                    <Text size={14} font="mont" color={theme.colors.main}>Сохранить</Text>
                    </SuperButton>
                </FlexBox>
              )}
              <FlexBox align="center" justify="center" direction="row" width="100%" height="60px" borderRadius="8px" background="rgb(57, 60, 70)" >
                <Text size={22} font="mont" color="#fff">{`${client.client.surname} ${client.client.name} ${client.client.patronymic}`}</Text>
              </FlexBox>
              <FlexBox gap={5} marginTop="5px" direction="column" width="100%">
                <FlexBox direction="row" width="100%" height="36px" background="rgb(248, 248, 248)" borderRadius="8px">
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">Баланс</Text>
                  </FlexBox>
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">{formatter.format(client.client.balance)}</Text>
                  </FlexBox>
                </FlexBox>
                <FlexBox direction="row" width="100%" height="36px" background="rgb(248, 248, 248)" borderRadius="8px">
                <FlexBox direction="row" width="100%" height="36px" background="rgb(248, 248, 248)" borderRadius="8px">
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">Телефон</Text>
                  </FlexBox>
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">{client.client.phone}</Text>
                  </FlexBox>
                </FlexBox>
                </FlexBox>
                <FlexBox direction="row" width="100%" height="36px" background="rgb(248, 248, 248)" borderRadius="8px">
                <FlexBox direction="row" width="100%" height="36px" background="rgb(248, 248, 248)" borderRadius="8px">
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">Оплачено</Text>
                  </FlexBox>
                  <FlexBox direction="row" width="100%" height="100%" align="center" justify="center">
                    <Text color={theme.colors.fonts.main} size={12} font="mont">{formatter.format(+client.client.spentMoneyCount)}</Text>
                  </FlexBox>
                </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox marginTop="10px" direction="column" width="100%" gap={5}>
                {
                  mobBtnsActions.map(b => 
                    <FlexBox gap={5} direction="column" key={b.id}>
                      <FlexBox   justify="space-between" padding="10px" align="center" borderRadius="8px" height="50px" background="rgb(99, 97, 100)" direction="row">
                        <FlexBox gap={10} direction="row" align="center" justify="center">
                        <Icon  stroke="#fff" fill="none" svgID={b.svgId} width={27} height={27}/>
                        <Text size={14} color="#fff" font="mont">{b.title}</Text>
                        </FlexBox>
                        <FlexBox onClick={() => mobHandler(b.id)} direction="row">
                          <Icon transform={b.active ? "rotate(180deg)" : "rotate(0deg)"} svgID="chevron" fill="none" width={24} height={24}/>
                        </FlexBox>
                      </FlexBox>
                      {
                        b.active && b.component
                      }
                    </FlexBox>
                  )
                }
              </FlexBox>
      </div>

     
      <div className={c.notification}>
        <ClientVisits showNewEntry={showNewEntry} clientId={clientCard.id} />
        <div className={`${c.itemNot} `}>
          <div className={c.add}>
            <div className={c.addContent}>
              <span>Напоминания</span>
              <img onClick={addRemindWindow} src={plus} alt="" />
            </div>
          </div>
          <Box
            className={c.reminders}
            ref={listInnerRef}
            onScroll={onScroll}
            gap={3}
            direction="row"
            width="100%"
            height="100%"
            padding="5px"
            overFlowY="auto"
          >
            <FlexBox direction="column" gap={5} width="100%">
              {reminders.clientReminders.map(
                (el) =>
                  el.status === ClientRemindersStatus.OPEN && (
                    <FlexBox
                      direction="row"
                      width="100%"
                      height="78px"
                      gap={5}
                      padding="0 5px"
                      background={theme.colors.colorForm}
                      border="1px solid rgba(0, 0, 0, 0.2)"
                      borderRadius="8px"
                    >
                      <FlexBox direction="row" height="100%" width="12%">
                        <img src={ring2} alt="" />
                      </FlexBox>
                      <FlexBox
                        gap={5}
                        direction="column"
                        width="100%"
                        height="100%"
                        padding="5px"
                      >
                        <FlexBox
                          direction="row"
                          width="100%"
                          justify="space-between"
                        >
                          <img src={row} alt="" />
                          <Text
                            color={theme.colors.fonts.secondary}
                            font="mont"
                            size={11}
                          >
                            {el.userName}
                          </Text>
                          <Text
                            color="black"
                            font="Inter"
                            size={12}
                            weight={600}
                          >
                            на {new Date(el.eventDate).toLocaleDateString()}
                          </Text>
                        </FlexBox>
                        <Text
                          color={theme.colors.fonts.secondary}
                          size={11}
                          font="mont"
                        >
                          {el.comment}
                        </Text>
                      </FlexBox>
                    </FlexBox>
                  )
              )}
            </FlexBox>
          </Box>
        </div>
        {/* <div className={`${c.itemNot} ${c.req}`}>
          <div className={c.add}>
            <div className={c.addContent}>
              <span>Обращения</span>
            </div>
          </div>
          <div className={c.reqContent}>
            <img src={phone} alt="" />
            <p>
              Список обращений клиента
            </p>
          </div>
        </div> */}
        <ClientAppeals  clientId={clientCard.id} showNewEntry={showNewEntry}/>
        <ClientQueue clientId={clientCard.id} showQueue={showQueue} />
      </div>
      <div className={c.photos}>
        <div className={`${c.photosItem} ${c.photosPhoto}`}>
          {photoLabel && (
            <div className={c.photoLabel}>
              <label className={c.photoLabelContainer}>
                <input
                  onChange={(e) => {
                    if (e.target.files) photoHandler(e.target.files[0]);
                  }}
                  type="file"
                />
                {newPhotoURL ? (
                  <FlexBox direction="row" width="130px" height="130px">
                    <img
                      style={{
                        borderRadius: "4px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      alt=""
                      src={newPhotoURL}
                    />
                  </FlexBox>
                ) : (
                  <>
                    <FlexBox
                      gap={10}
                      direction="row"
                      align="center"
                      justify="space-between"
                    >
                      <FlexBox padding="0 5px" direction="row">
                        <Text align="left" color="black" font="mont" size={18}>
                          Поместите фото в центре
                        </Text>
                      </FlexBox>

                      <img src={addPhoto} alt="" />
                    </FlexBox>
                  </>
                )}
              </label>
              <FlexBox
                gap={5}
                align="center"
                justify="center"
                direction="row"
                width="100%"
              >
                <Text size={14} font="mont" color="black">
                  нажмите
                </Text>
                <label onClick={uploadClientPhoto} className={c.photoLabelBtn}>
                  загрузить
                </label>
              </FlexBox>
            </div>
          )}
          <header className={c.head}>
            <span>Фотографии до и после выполнения работ</span>
            <div
              onClick={() => showPhotoLabel((prev) => !prev)}
              className={c.plusContainer}
            >
              <img src={plus} alt="" />
            </div>
          </header>
          <div className={c.photosContainer}>
            {!!clientCard.workPhotos.length && (
              <AliceCarousel
                responsive={responsive}
                mouseTracking
                items={clientCard.workPhotos.map((c, i) => (
                  <img
                    onDragStart={handleDragStart}
                    alt=""
                    key={c + i}
                    src={c}
                  />
                ))}
              />
            )}
          </div>
        </div>
        <ClientComments
          clientId={clientCard.id}
          comments={clientCard.comments}
        />
      </div>
      <Works isProPlus = {user.user?.tariff === tariffType.SuperPRO ? true : false} isOwner = {teammate?.isOwner ? teammate?.isOwner : false} userId={user.user!.id} clientRights={teammate?.clientRights} client={clientCard} />
    </div>
  );
};
