import React, { useState } from "react";
import userInfoStyles from './userInfoStyles'
import { ForMe } from "./ForMe";
import { Company } from "./Company";
import { FlexBox } from "../styles/components/FlexBox";
import { Border } from "../styles/components/Border";
type stateType = ReturnType<typeof ForMe | typeof Company>

type userInfoType = {
    userId: string,
    auth: boolean
}
export const UserInfo = (props: userInfoType) => {
    const classes = userInfoStyles()
    const [state, setState] = useState<stateType>(<Company auth = {props.auth} userId={props.userId} />)


    const act = state.type.name === 'ForMe' ? classes.active : ''
    const act2 = state.type.name === 'Company' ? classes.active : ''


    return (
        <FlexBox justify="center" align="center" height="100%" direction="row" >
            <FlexBox direction="row">
                <FlexBox direction="column" >
                    <div className={classes.request}>
                        Пожалуйста, ответьте  на <br />несколько вопросов
                        для <br />  настройки вашего аккаунта
                    </div>
                    <div className={classes.instruction}>
                        <div className={classes.aim}>Зачем это нужно? </div>
                        <div className={classes.textDescription}>
                            Укажите роль в компании и информацию чем вы <br />
                            занимаетесь. Эти данные помогут нам лучше
                            понимать профиль наших клиентов и сделать
                            продукт лучше.
                        </div>
                    </div>
                </FlexBox>


                <FlexBox gap={20} direction="row">
                    <FlexBox direction="row" height="100%" >
                        <Border height={"100%"} width={"1px"} borderColor="#c4c4c4" />
                    </FlexBox>
                    <FlexBox direction="column">
                        <div className={classes.textItem}>Планируете использовать? </div>
                        <div className={classes.btnContainer}>
                            <button className={`${classes.btn}  ${act}`} onClick={
                                (e) => {
                                    setState(<ForMe userId={props.userId}  auth = {props.auth}/>)
                                }
                            } > Для себя
                            </button>

                            <button className={`${classes.btnRight} ${act2}`} onClick={
                                () => {

                                    setState(<Company userId={props.userId} auth = {props.auth} />)

                                }
                            }> Для компании
                            </button>
                        </div>



                        {state}
                    </FlexBox>



                </FlexBox>
            </FlexBox>

        </FlexBox>
    )
}