import {  useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux"
import { v1 } from "uuid"
import { Reorder } from "framer-motion";
import { Item } from "./Item"
import { stateUserType, tariffType } from "../store/UserReducer"
import { FlexBox } from "../styles/components/FlexBox"
import { ItemMobile } from "./ItemMobile"
import { Box } from "../styles/components/Box"
import { theme } from "../styles/components/Theme.styled"
import { SuperButton } from "../styles/components/SuperButton"
import { showPayWindowAC } from "../store/ModalWindowsReducer"
import {PaymentHistory} from "./History"
import { teammateProSettingsType } from "../apiTypes/root/userProPlus.type";

enum ActiveSubComponent {
    tarif = "TARIF",
    history = "HISTORY",
    team = "TEAM"
}
type tariffItemsType = {
    id: string,
    isCurrent: boolean,
    tariff: tariffType,
    title: string,
    cost: string,
    userCount: string,
    active: boolean,
    opportunities: string[],
    subTitle?: string
}

type proBtnsType = {
    id: string,
    title: string,
    item: ActiveSubComponent,
    active?: boolean,
    style?: any,
    cb?: () => void,
}

type actionType = {
    id: string,
    item: string,
    active?: boolean,
    cb?: () => void,
    style?: any
}

const selectUserProPlus = (state:AppRootState) => state.userProPlusState

export const Tariffs = () => {
    const [workHeader, setWorkHeader] = useState(true)

    const dispatch:thunkDispatchRootType = useDispatch()
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
        const userProPlusState = useSelector(selectUserProPlus)
    
    const tariffs: tariffItemsType[] = [
        { id: v1(), isCurrent: false, tariff: tariffType.FREE, title: "Бесплатный", cost: "0", userCount: "1", active: true, opportunities: ["Клиенты", "Расписание", "еще что то нужно вспомнить"] },
        { id: v1(), isCurrent: false, tariff: tariffType.PRO, title: "PRO", cost: "99", userCount: "1", active: false, opportunities: ["Все из “Бесплатный” +", "Расписание", "еще что то нужно вспомнить"] },
        { id: v1(), isCurrent: false, tariff: tariffType.SuperPRO, title: "PRO", cost: "125", userCount: "1+", subTitle: "plus", active: false, opportunities: ["Все из “PRO” +", "Все из PRO", "Какая-то хрень"] },
    ]

    const settingButtons:proBtnsType[] = [
        {id: v1(), title:"Тариф",item: ActiveSubComponent.tarif},
        { id: v1(), title: "История оплат",item: ActiveSubComponent.history, active: false, style: { background: workHeader ? "red" : "" }, cb: () => setWorkHeader(true) },
        {id: v1(), title:"Команда",item: ActiveSubComponent.team},
        
    ]
    const [activeSubComponent, setActiveSubComponent] = useState<ActiveSubComponent>(ActiveSubComponent.tarif)
          const [teammate, setTeammate] = useState<null | teammateProSettingsType>(null)
    
    const isAllowed = user.user && (user.user.tariff === tariffType.PRO || user.user.tariff === tariffType.SuperPRO)

    const [cardsItem, setCardItem] = useState(tariffs)
    const findActiveTariff = () => {
        const tariffIdx = tariffs.findIndex(el => el.tariff === user.user?.tariff)
        const copyState = [...tariffs]
        const activeEl = copyState[tariffIdx]
        copyState.splice(tariffIdx, 1)
        copyState.splice(1, 0, activeEl)
        setCardItem(copyState.map((el,i) => i === 1 ? {...el, active: true} : {...el, active: false}))
        console.log(cardsItem)
    }
    
    useEffect(() => {
        if (user.user?.tariff) findActiveTariff()
    }, [user.user?.tariff])

  useEffect(() => {
    setCardItem(cardsItem.map((el,i) => i === 1 ? {...el, active: true} : {...el, active: false}))
  },[JSON.stringify(cardsItem)])

  useEffect(() => {
    if (userProPlusState.teammates.teammates.length) {
      const me = userProPlusState.teammates.teammates.find(
        (t) => t.user.userId === user.user?.id
      );
      if (me) {
        setTeammate(me);
      }
    }
  }, [userProPlusState.teammates.teammates.length]);

    return (

        <FlexBox style={{position: "relative"}} height="100%" padding="0 0 30px 0" className="tariffContainer" direction="column" align="center" width="100%" justify="start">
            {
                isAllowed &&
                <Box  direction="row" height="64px"  style={{borderRadius:"8px",  border:"1px solid rgba(0,0,0,0.2)"}} position="absolute" top="15px"  background={theme.colors.colorForm} width="100%">
                    <FlexBox direction="row" width="100%" height="100%" justify="space-around" align="center">
                        {
                            settingButtons.map(el => <SuperButton onClick={() => setActiveSubComponent(el.item)}  height="36px" maxWidth="120px" minWidth="120px" varinat={el.item === activeSubComponent ? "contained" : "settingTariff"}  hover hoverColor={theme.colors.main} key={el.id}>{el.title}</SuperButton>)
                        }
                        <SuperButton disabled = {(user.user?.tariff === tariffType.SuperPRO) && !teammate?.isOwner && !teammate?.settingsRights.payManage} onClick={() => dispatch(showPayWindowAC(true))} maxWidth="186px" minWidth="186px" height="36px" hover hoverColor="#262626" varinat="contained" >Пополнить баланс</SuperButton>
                    </FlexBox>
                </Box>
            }
            
            <Box width="100%" height="auto"  padding="50px 0 0 0">
                {activeSubComponent === ActiveSubComponent.tarif && (
                    <FlexBox className="tariffCardItems" width="100%" marginTop="100px" direction="row" gap={34} as={Reorder.Group} axis="x" onReorder={setCardItem} values={cardsItem}>
                        {cardsItem.map((item) => (
                            <Item userId={user.user?.id as string} profile={user.user} key={item.id} item={item} />
                        ))}
                    </FlexBox>
                )}
                {activeSubComponent === ActiveSubComponent.history && (
                    <PaymentHistory /> // Здесь отображается история оплат
                )}
            </Box>
            <Box className="tariffCardItemsMobile" height="100%" overFlowY="auto" top= {isAllowed ? "35px" : "0"} gap={30} position="relative" width="100%">
                {cardsItem.map((item) => (
                    <ItemMobile userId={user.user?.id as string} profile={user.user} key={item.id} item={item} />
                ))}
            </Box>
        </FlexBox>
    )
}

