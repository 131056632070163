import { useEffect, useRef } from "react"
import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"
import { theme } from "../../styles/components/Theme.styled"
import { ClientRemindersStatus, remindersStateType } from "../../apiTypes/root/reminders.type"
import ring2 from "../../assets/ring2.svg";
import c from "./clientCard.module.css";
import row from "../../assets/row.svg";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { getReminderClientTC } from "../../store/RemindersReducers"


type propsType = {
    clientId: string
}
const selectReminders = (state:AppRootState) => state.remindersState
export const ClientReminders = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const listInnerRef = useRef<any>(null);
    const reminders = useSelector(selectReminders)
      const onScroll = () => {
        //@ts-ignore
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          return new Promise((res, rej) => {
            setTimeout(() => res("data"), 500);
          }).then((res) => console.log("res", res));
        }
      };
      useEffect(() => {
        if(props.clientId){
            dispatch(getReminderClientTC(props.clientId));
        }
      },[props.clientId])
    return(
          <Box
              className={c.reminders}
              ref={listInnerRef}
              onScroll={onScroll}
              gap={3}
              direction="row"
              width="100%"
              height="250px"
              padding="5px"
              overFlowY="auto"
            >
              <FlexBox direction="column" gap={5} width="100%" height="250px">
                {reminders.clientReminders.map(
                  (el) =>
                    el.status === ClientRemindersStatus.OPEN && (
                      <FlexBox
                        direction="row"
                        width="100%"
                        height="78px"
                        gap={5}
                        padding="0 5px"
                        background={theme.colors.colorForm}
                        border="1px solid rgba(0, 0, 0, 0.2)"
                        borderRadius="8px"
                      >
                        <FlexBox border="1px solid red" direction="row" height="100%" width="12%">
                          <img src={ring2} alt="" />
                        </FlexBox>
                        <FlexBox
                          gap={5}
                          direction="column"
                          width="100%"
                          height="100%"
                          padding="5px"
                        >
                          <FlexBox
                            direction="row"
                            width="100%"
                            justify="space-between"
                          >
                            <img src={row} alt="" />
                            <Text
                              color={theme.colors.fonts.secondary}
                              font="mont"
                              size={11}
                            >
                              {el.userName}
                            </Text>
                            <Text
                              color="black"
                              font="Inter"
                              size={12}
                              weight={600}
                            >
                              на {new Date(el.eventDate).toLocaleDateString()}
                            </Text>
                          </FlexBox>
                          <Text
                            color={theme.colors.fonts.secondary}
                            size={11}
                            font="mont"
                          >
                            {el.comment}
                          </Text>
                        </FlexBox>
                      </FlexBox>
                    )
                )}
              </FlexBox>
            </Box>
    )
}