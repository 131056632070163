import c from "./clientCard.module.css";
import plus from "../../assets/actions/plus.svg";
import { FlexBox } from "../../styles/components/FlexBox";
import { useEffect, useRef, useState } from "react";
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { changeClientCardVistsPageAC, getClientCardNotesTC } from "../../store/ClientCardReducer";
import { Box } from "../../styles/components/Box";
import { Text } from "../../styles/components/Text";
import { theme } from "../../styles/components/Theme.styled";
import { Icon } from "../../styles/components/Icon";
import { noteHelper } from "../../utils/note/note.helper";

type propsType = {
  clientId: string;
  showNewEntry: () => void
};
const selectProPlus = (state: AppRootState) => state.userProPlusState
const selectNotes = (state:AppRootState) => state.notes
const selectClientCard = (state: AppRootState) => state.clientCard;
export const ClientVisits = (props: propsType) => {
  const clientCard = useSelector(selectClientCard);
  const scrollRef = useRef(null)
  const notes = useSelector(selectNotes)
  const userProPlusState = useSelector(selectProPlus)
  const [isDisableScroll, setDisableScroll] = useState<boolean>(false)
  const maxPage = Math.ceil(clientCard.notes.totalCount / clientCard.notes.pageSize)
const dispatch:thunkDispatchRootType = useDispatch()
    const onScroll = () => {
      if(scrollRef.current){
        const {scrollTop,scrollHeight,clientHeight} = scrollRef.current
        // console.log("top", clientHeight, scrollTop,scrollHeight)
        if(((scrollTop + clientHeight) >= scrollHeight) && (clientCard.notes.page < maxPage)){
          // console.log("scroll", scrollTop,)
          dispatch(changeClientCardVistsPageAC(clientCard.notes.page + 1))
          // setDisableScroll(true)
        } 
        if(scrollTop <= 0 && clientCard.notes.page > 1){
          // dispatch(changeClientCardVistsPageAC(clientCard.notes.page - 1))
          // setDisableScroll(true)

        }
      }
    }
    useEffect(() => {
      if (props.clientId) {
        dispatch(getClientCardNotesTC(props.clientId, clientCard.notes.page, clientCard.notes.pageSize));
      }
    }, [props.clientId, notes.length, userProPlusState.notesForTeam.length, clientCard.notes.page]);
    useEffect(() => {
      if(isDisableScroll) { 
        setTimeout(() => {
          setDisableScroll(false)
        }, 500)
      }
       
    },[clientCard.notes.page])
    console.log("klo", clientCard.notes.page)

  return (
    <FlexBox
      borderRadius="8px"
      border="1px solid rgba(68, 68, 68, 0.2)"
      direction="column"
      width="100%"
      height="100%"
      gap={5}
    >
      <div className={c.add}>
        <div className={c.addContent}>
          <span>Визиты</span>
          {/* <img onClick={props.showNewEntry } src={plus} alt="" /> */}
        </div>
      </div>
      <Box onScroll={onScroll} ref={scrollRef} gap={5} overFlowY="scroll" height="100%" width="100%">
        {clientCard.notes.lastNotes.map((n, i) => (
          <FlexBox
            height="54px"
            width="100%"
            borderRadius="8px"
            border="1px solid rgba(68, 68, 68, 0.2)"
            key={n.date + i}
            direction="row"
            padding="10px 0"
           
            background={theme.colors.colorForm}
          >
            <FlexBox align="center" justify="center" direction="row" height="100%" width="15%">
                <Icon stroke={theme.colors.main} transform="scale(1.2)" fill="#fff" svgID={noteHelper.getSvgId(n.status)} width={24} height={24}/>
            </FlexBox>
            <FlexBox direction="column" width="100%" height="100%">
              <FlexBox gap={5} align="center" justify="center" direction="row">
                <Text
                  color={theme.colors.fonts.secondary}
                  size={11}
                  font="mont"
                >
                  {new Date(n.date).toLocaleString("ru", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </Text>
                <FlexBox  align="center" justify="center" direction="row">
                  <Icon fill="#fff" svgID="calendar-clock" width={24} height={26} />
                </FlexBox>
                <FlexBox  align="center" justify="center" direction="row">
                  <Text
                  align="center"
                    size={13}
                    font="mont"
                    color={theme.colors.fonts.secondary}
                  >
                    {`${n.start} - ${n.end}`}
                  </Text>
                </FlexBox>
              </FlexBox>
              <FlexBox align="center" justify="center"  direction="row" height="100%" width="100%">
                <Text color={theme.colors.fonts.main} size={12} font="mont">{n.masterName}</Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
        {/* {lastVisits.map((el) => (
            <div className={c.currentVisit}>
              <span>{el.date}</span>
              <div>
                <img src={time} alt="" />
                <span>
                  {/* {generateSesionTime(el.time, el.duration)} */}
        {/* </span> */}
        {/* </div> */}
        {/* </div> */}
        {/* ))}  */}
      </Box>
    </FlexBox>
  );
};
