import { css, styled } from "styled-components";

type propsType = {
    component: "any" | "TimeTable",
    axis?: "column" | "row",
    maxWidth?:string,
    width?:string,
}
export const Column = styled.div<propsType>`
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: ${props => props.width ? props.width : "100%"};
    display: flex;
    align-items: center;
    justify-content: center;
    
${props => props.component === "TimeTable" && css<propsType>`
    gap: 12px;
    width: ${props => props.width ? props.width : "100%"}
    display: flex;
    justify-content: start;
    gap: 10px;
    padding-left: 10px;
    /* max-width: ${props => props.maxWidth ? props.maxWidth : "132px"}; */
    
`};
${props => props.axis === "column" && css<propsType>`
    flex-direction: column;
    gap: 3px;
    
`};

`