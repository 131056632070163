import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { useDispatch, useSelector } from "react-redux";
import { setTargetTeammateTC } from "../store/UserProPlusReducer";
import { FlexBox } from "../styles/components/FlexBox";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { Avatar } from "../styles/components/Avatar";
import { TimeTableRights } from "./rightsComponent/TimeTableRights";
import { ClientRights } from "./rightsComponent/ClientRights";
import { SettingsRights } from "./rightsComponent/SettingsRights";
import { teammateProSettingsType, timeTableRightsType } from "../apiTypes/root/userProPlus.type";

const selectTeammate = (state: AppRootState) => state.userProPlusState;
export const TeammateRights = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const { teammateId } = useParams();
  const userProPlus = useSelector(selectTeammate);
  const teammate = userProPlus.targetTeammate;
  useEffect(() => {
    if (teammateId) dispatch(setTargetTeammateTC(teammateId));
  }, [teammateId]);
  return (
    <FlexBox direction="column" width="100%" height="100%">
      <FlexBox gap={5} direction="row" width="100%">
        <NavLink to={"/proSettings"}>
          <Text color="black" size={18} font="mont">{`Настройки>`}</Text>
        </NavLink>
        <NavLink to={"/proSettings/team"}>
          <Text color="black" size={18} font="mont">{`Команда>`}</Text>
        </NavLink>
        <Text color="black" size={16} font="mont">{`${teammate?.user.lastName} ${teammate?.user.firstName} ${teammate?.user.thirdName}`}</Text>
      </FlexBox>
      <FlexBox
                  className="timeTableHeader"
                  direction="row"
                  background={theme.colors.colorForm}
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="8px"
                  align="center"
                  justify="start"
                  height="64px"
                  padding="10px 10px 10px 20px"
                  marginTop="20px"
                  gap={15}
                >
                 <Avatar size="large" src={teammate?.user.avatarUrl}/>
                 <Text color="black" size={16} font="mont">{`${teammate?.user.lastName} ${teammate?.user.firstName} ${teammate?.user.thirdName}`}</Text>

                </FlexBox>
                <FlexBox marginTop="20px" direction="row" gap={20} width="100%" height="100%"> 
                    <TimeTableRights payload={teammate.timeTableRights}/>
                    <ClientRights payload={teammate.clientRights}/>
                    <SettingsRights payload={teammate.settingsRights}/>
                </FlexBox>
    </FlexBox>
  );
};
