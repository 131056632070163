import { useEffect, useState } from "react";
import { Box } from "../../../styles/components/Box";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../../../store/storeRedux";
import {
  getWorkDaysTC,
  getWorkTimesForDayTC,
} from "../../../store/CalendarReducer";
import { Table } from "../../../styles/components/Table";
import { TableRow } from "../../../styles/components/TableRow";
import { Column } from "../../../styles/components/Column";
import { stateUserType } from "../../../store/UserReducer";
import { TimeTableCellContainer } from "../../../styles/components/TimeTableCellContainer";
import { ButtonNote } from "../../../styles/components/ButtonNote";
import sprite from "../images/sprite.svg";
import { getNotesTC, notesStateType } from "../../../store/NotesReducer";
import {
  selectedMasterType,
  selectMasterAC,
  toggleSelectModeAC,
} from "../../../store/CommonReducers";
import { Note } from "../../Note";
import {
  getFreeTimesForTeamTC,
  getRenderTimesForTeamTC,
  getTeammatesForTimeTableTC,
  getWorkTimesForTeamTC,
  setActiveDateForTimeTableAC,
} from "../../../store/UserProPlusReducer";
import { NoteForTeam } from "./NoteForTeam";
import { Icon } from "../../../styles/components/Icon";
import { sessionsType } from "../../../store/SessionsReducer";
import { calendarHelper } from "../../../utils/calendar/calendar-helper";
import { NavLink } from "react-router-dom";
import { FlexBox } from "../../../styles/components/FlexBox";
import { Text } from "../../../styles/components/Text";
import { theme } from "../../../styles/components/Theme.styled";
import { teammateProSettingsType } from "../../../apiTypes/root/userProPlus.type";
type propsType = {
  user: stateUserType;
  day: Date;
  sessions: sessionsType;
  
  showNoteWindow: (
    date?: string,
    time?: string,
    duration?: number,
    master?: null | selectedMasterType
  ) => void;
};

const selectUserProPlusState = (state: AppRootState) => state.userProPlusState;
export const TimeTableForDay = (props: propsType) => {
  const userProPlusState = useSelector(selectUserProPlusState);
  const dispatch: thunkDispatchRootType = useDispatch();
    const [teammate, setTeammate] = useState<null | teammateProSettingsType>(null)

  // const [workTimesLength, setWorkTimeLength] = useState(0);
  const date = new Date(
    props.day.toLocaleDateString().split(".").reverse().join("-")
  ).toISOString();
  useEffect(() => {
    
    // dispatch(getNoteForTeamInDayTC(date));
    dispatch(getWorkTimesForTeamTC(date));
    dispatch(getFreeTimesForTeamTC(date));
    // dispatch(getWorkTimesForDayTC(calendarHelper.localeStringToDate(props.day.toLocaleDateString()).toISOString()))
    dispatch(
      getRenderTimesForTeamTC(
        calendarHelper
          .localeStringToDate(props.day.toLocaleDateString())
          .toISOString()
      )
    );
    dispatch(setActiveDateForTimeTableAC(date));
  }, [props.day, JSON.stringify(userProPlusState.notesForTeam)]);

  useEffect(() => {
    return () => {
      dispatch(setActiveDateForTimeTableAC(""));
      
    };
  }, []);
 
  useEffect(() => {
    if(!userProPlusState.teammatesForTimeTable.teammates.length) dispatch(getTeammatesForTimeTableTC(userProPlusState.teammatesForTimeTable.page,userProPlusState.teammatesForTimeTable.pageSize,date))
   },[userProPlusState.teammatesForTimeTable.page])
   useEffect(() => {
    if (userProPlusState.teammates.teammates.length) {
      const me = userProPlusState.teammates.teammates.find(
        (t) => t.user.userId === props.user.user?.id
      );
      if (me) {
        setTeammate(me);
      }
    }
  }, [userProPlusState.teammates.teammates.length]);
  return (
    <Box style={{overflowX: "hidden"}} padding="0" direction="row" position="relative" overFlowY="auto">
      <Table  component="TimeTable">
        {!Object.values(userProPlusState.teammateWorkTimes).length && (
          <FlexBox
            align="center"
            justify="center"
            height="100%"
            width="100%"
            direction="column"
          >
            <Text color={theme.colors.fonts.main} size={16} font="mont">
              У вас нет ниодного активного дня, для заполнения рабочего времени
            </Text>
            <p>
              <NavLink to={"settings"}>перейдите по ссылке</NavLink>
            </p>
          </FlexBox>
        )}
        {userProPlusState.renderTimes.map((t, i) => (
          <TableRow  component="TimeTable" key={i} colors="other">
            <FlexBox align="center" justify="center" maxWidth="104px" minWidth="104px" height="100%" direction="row" >
             <Text size={12} font="mont" color={theme.colors.fonts.main}> {t}</Text> 
              </FlexBox>
            {/* {userProPlusState.teammatesForTimeTable.teammates.length > 0 && userProPlusState.teammatesForTimeTable.teammates.map((us, idx) =>
             
              userProPlusState.teammateWorkTimes[us.user.id] && userProPlusState.teammateWorkTimes[us.user.id].some((w) => parseInt(w.start) === parseInt(t))
              ? (
                <TimeTableCellContainer width="100%">
                  {userProPlusState.notesForTeam.map((el) =>
                    el.userId === us.user.id &&
                    parseInt(el.time) === parseInt(t) ? (
                      <NoteForTeam
                      teammate={teammate}
                        isLast={
                          idx + 1 ===
                          userProPlusState.teammates.teammates.length
                        }
                        el={el}
                      />
                    ) : (
                      <></>
                    )
                  )}
                  
                
                </TimeTableCellContainer>
              ) 
              : (
                <TimeTableCellContainer
                  free
                  width="100%"
                ></TimeTableCellContainer>
              )
            )
            } */}
            { 
            
            userProPlusState.teammatesForTimeTable.teammates.length > 0 && userProPlusState.teammatesForTimeTable.teammates.map((us, idx) =>
              Object.keys(userProPlusState.teammateWorkTimes).find(
                (tm) =>
                   tm === us.user.id  &&
                  userProPlusState.teammateWorkTimes[us.user.id].some((w) => parseInt(w.start) === parseInt(t))
              ) 
              ? (
                <TimeTableCellContainer width="100%">
                  {userProPlusState.notesForTeam.map((el) =>
                    el.userId === us.user.id &&
                    parseInt(el.time) === parseInt(t) ? (
                      <NoteForTeam
                      teammate={teammate}
                        isLast={
                          idx + 1 ===
                          userProPlusState.teammates.teammates.length
                        }
                        el={el}
                      />
                    ) : (
                      <></>
                    )
                  )}
                 
                  { userProPlusState.teammateFreeTimes.hasOwnProperty(us.userId) &&
                      userProPlusState.teammateFreeTimes[us.userId].map(
                        (f) =>
                          parseInt(f.start) === parseInt(t) &&
                           (
                            
                            <ButtonNote
                              onClick={() =>
                                props.showNoteWindow(
                                  props.day.toISOString(),
                                  f.start,
                                  f.endNumber - f.startNumber,
                                  {
                                    masterID: us.user.id,
                                    avatarUrl: us.user.avatarUrl,
                                    masterName: us.user.firstName,
                                  }
                                )
                              }
                              duration={f.endNumber - f.startNumber}
                              time={f.start}
                            >
                              <Icon width={18} height={16} svgID="people" />
                            </ButtonNote>
                          )
                      )
                  }
                </TimeTableCellContainer>
              ) : (
                <TimeTableCellContainer
                  free
                  width="100%"
                ></TimeTableCellContainer>
              )
            )}
          </TableRow>
        ))}
      </Table>
    </Box>
  );
};
