import { useEffect } from "react";
import { TableHeader } from "../styles/components/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { Column } from "../styles/components/Column";
import { Avatar } from "../styles/components/Avatar";
import { Text } from "../styles/components/Text";
import {
  changePageForTeammateAC,
  getAllTeammatesForAdminTC,
  getTeammatesForTimeTableTC,
} from "../store/UserProPlusReducer";
import { userProPlusStateType } from "../apiTypes/root/userProPlus.type";
import { NextPrevButton } from "../styles/components/NextButton";
import { FlexBox } from "../styles/components/FlexBox";
import { Box } from "../styles/components/Box";

type propsType = {
  userID: string | null;
  day: Date;
 
};
const selectUser = (state: AppRootState) => state.user;
const selectUserProPlusState = (state: AppRootState) => state.userProPlusState;
export const TeamMateHeader = (props: propsType) => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const user = useSelector(selectUser);
  const userProPlusState = useSelector(selectUserProPlusState);
  const prev = () => {
    if ((userProPlusState.teammatesForTimeTable.page - 1) > 0) {
      dispatch(
        changePageForTeammateAC(userProPlusState.teammatesForTimeTable.page - 1)
      );
    }
  };
  const next = () => {
    if ((userProPlusState.teammatesForTimeTable.page + 1) <= userProPlusState.teammatesForTimeTable.pagesCount) {
      dispatch(
        changePageForTeammateAC(userProPlusState.teammatesForTimeTable.page + 1)
      );
    } else {
        return
    }
  };
  const date = new Date(
    props.day.toLocaleDateString().split(".").reverse().join("-")
  ).toISOString();
  useEffect(() => {
    dispatch(
      getTeammatesForTimeTableTC(
        userProPlusState.teammatesForTimeTable.page,
        userProPlusState.teammatesForTimeTable.pageSize,
        date
      )
    );
  }, [userProPlusState.teammatesForTimeTable.page, props.day]);
  return (
    <>
      {/* <TableHeader padding="0px" type="dynamic" offset="medium"> */}
      <FlexBox marginTop="20px" position="relative" borderRadius="8px" background="rgb(105, 103, 106)" height="60px"  direction="row" width="100%">
      <FlexBox direction="column" minWidth="104px" justify="center" align="center" width="114px"  >
          <Text size={10} color="#fff" font="mont" transform="capitalize">
            {props.day.toLocaleString("ru", { weekday: "long" })}
          </Text>
          <Text size={10} color="#fff" font="mont">
            {props.day.toLocaleString("ru", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </FlexBox>
       
          <FlexBox position="relative"  direction="row" width="100%" height="100%">
          {
        userProPlusState.teammatesForTimeTable.totalCount > 6 && 
        <Box transform="scale(0.8)" position="absolute"   left="5px" top="9px" direction="row" width="25px" height="100%">
        <NextPrevButton  onClick={prev} type="prev" />
      </Box>
       }
          {userProPlusState.teammatesForTimeTable.teammates.map((el) => (
          <FlexBox justify="center" gap={10} align="center" direction="row" width="100%" height="100%" >
            <Avatar size="medium" src={el.user && el.user.avatarUrl} alt="" />
            <Text color="#fff" size={12} font="mont">{el.user && el.user.firstName}</Text>
          </FlexBox>
        ))}
        {
          userProPlusState.teammatesForTimeTable.totalCount > 6 &&
          <Box transform="scale(0.8)" position="absolute"  direction="row" width="25px" height="100%" top="9px" right="5px">
          <NextPrevButton onClick={next} type="next" />
          </Box>
        }
       
          </FlexBox>
        
        
         </FlexBox>
        
        
      {/* </TableHeader> */}
    </>
  );
};
