import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { clientsStateType, getClientsTC, setClientsAC, updateClientAC, updateClientsTC, updateClientTeammateTC } from '../store/ClientsReducer';
import "react-phone-input-2/lib/style.css";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from '../store/storeRedux';
import { stateUserType, tariffType } from '../store/UserReducer';
import { editCardAC } from '../store/ModalWindowsReducer';
import { clientUpdateRequestType } from '../apiTypes/responses/clientResponsesType';
import { Box } from '../styles/components/Box';
import { theme } from '../styles/components/Theme.styled';
import { TextField } from '../styles/components/TextField';
import { SuperButton } from '../styles/components/SuperButton';

type propsType = {

    editedClient: clientsStateType | undefined
    setClients: (clients: clientsStateType[]) => void
}
export const EditCard = (props: propsType) => {

    const dispatch = useDispatch()
    const user = useSelector<AppRootState, stateUserType>(state => state.user)
    const clients = useSelector<AppRootState, clientsStateType[]>(state => state.client)
    const [value, setValue] = useState<any>()
    const [surname, setSurname] = useState(props.editedClient?.surname)
    const [name, setName] = useState(props.editedClient?.name)
    const [patronymic, setPatronymic] = useState(props.editedClient?.patronymic)
    const { register, handleSubmit } = useForm()
    const onSubmit = (data: any) => {
        const obj = { ...data, phone: value }
        if (obj.surname !== props.editedClient?.surname || obj.name !== props.editedClient?.name || obj.patronymic !== props.editedClient?.patronymic || value !== props.editedClient?.phone) {
            const data:clientUpdateRequestType = {
                surname: obj.surname[0].toUpperCase() + obj.surname.slice(1),
                name: obj.name[0].toUpperCase() + obj.name.slice(1),
                patronymic: obj.patronymic[0].toUpperCase() + obj.patronymic.slice(1),
                phone: value
            }
            if(user.user?.tariff === tariffType.SuperPRO){
                dispatch(updateClientTeammateTC(props.editedClient?.id as string, data ))
            } else {
                dispatch(updateClientsTC(props.editedClient?.id, data ))
            }

        } else {
            console.log('no changes');

        }



    }
    useEffect(() => {
        props.setClients(clients)


    }, [onSubmit])
    useEffect(() => {
        setValue(props.editedClient?.phone)
        props.setClients(clients)

    }, [])


    return (
        <>
            <button style={{
                width: '100vw',
                height: '100vh',
                background: 'black',
                opacity: 0.6,
                position: 'fixed',
                zIndex: 50,
                border: 'none',
                top: 0,
                left: 0
            }
            }
                form="my-form"
                type="submit"
            >

            </button>
            <Box className='editCard' gap={20}  as={"form"} width='100%' height='88px' background={theme.colors.colorForm} borderRaduis='12px' position='absolute' zIndex={65} top='0' left='0'  id='my-form' onSubmit={handleSubmit(onSubmit)} direction='row' style={{padding:"24px"}}>
                <TextField height='40px' width='100%' border='1px solid rgba(228, 228, 228, 0.9)' transform='capitalize' {...register('surname')} value={surname} onChange={(e) => setSurname( (e.currentTarget.value ) )}  type="text" />
                <TextField height='40px' width='100%' border='1px solid rgba(228, 228, 228, 0.9)' transform='capitalize' {...register('name')} value={name} onChange={(e) => setName(e.currentTarget.value)}  type="text" />
                <TextField height='40px' width='100%' border='1px solid rgba(228, 228, 228, 0.9)' transform='capitalize' {...register('patronymic')} value={patronymic} onChange={(e) => setPatronymic(e.currentTarget.value)} type="text" />
                <PhoneInput
                    onlyCountries={['by', 'ru']}
                    country={'ru'}
                    value={value}
                    onChange={(val,format,e) => setValue(e.target.value)}
                    inputStyle={{

                        height: '40px'
                    }}

                />

                <SuperButton varinat='close' onClick={(e) => {dispatch(editCardAC(false))}} className='editCardDesktop'>x</SuperButton>
                <SuperButton height='40px' minWidth='113px' maxWidth='113px' className='editCardClose' varinat='contained' onClick={(e) => {dispatch(editCardAC(false))}}>Закрыть</SuperButton>
            </Box>
        </>
    )
}