import { useEffect, useId, useState } from "react"
import { clientRightsType, timeTableRightsType } from "../../apiTypes/root/userProPlus.type"
import { CheckBox } from "../../styles/components/CheckBox"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"
import { useForm } from "react-hook-form"
import { theme } from "../../styles/components/Theme.styled"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { setSuccesMessageClientRightsAC } from "../../store/AppReducer"
import { changeClientRightsTC } from "../../store/UserProPlusReducer"

type propsType = {
    payload: clientRightsType 
}
const selectAppState = (state:AppRootState) => state.appState
export const ClientRights = (props: propsType) => {
    const appState = useSelector(selectAppState)
    const dispatch:thunkDispatchRootType = useDispatch()
    const initState: {id: string, field: keyof clientRightsType, title: string,value: boolean}[] = [
        {id: useId(), field: "canReadTeammateClients" ,title: "Полный список", value: props.payload.canReadTeammateClients},
        {id: useId(), field: "canEditAllClients" ,title: "Редактировать (всех)", value: props.payload.canEditAllClients},
        {id: useId(), field: "candDeleteOwnClients" ,title: "Удалять (своих)", value: props.payload.candDeleteOwnClients},
        // {id: useId(), field: "candReadTeammateNotes" ,title: "Удаление (своих)", value: props.payload.},
        {id: useId(), field: "canDeleteEverybody" ,title: "Может удалять всех клиентов", value: props.payload.canDeleteEverybody},
        {id: useId(), field: "canSeePhoneTeammatesClients" ,title: "Показывать Номер телефона", value: props.payload.canSeePhoneTeammatesClients},
        {id: useId(), field: "canSeeBalanceTeammatesClients" ,title: "Видит финансы свои клиентов при включении галочки видит финансы всех клиентов", value: props.payload.canSeeBalanceTeammatesClients},
        {id: useId(), field: "canTopUpBalanceTeammatesClients" ,title: "Пополнять финансы", value: props.payload.canTopUpBalanceTeammatesClients},
        {id: useId(), field: "canAddWork" ,title: "Добавление работ", value: props.payload.canAddWork},
        {id: useId(), field: "canEditWork" ,title: "Редактирование оплаченых работ", value: props.payload.canEditWork},
    ]
    let [initValues, setInitValues] = useState<boolean[]>([])

    const [state, setState] = useState(initState)
    const [editMode, setEditMode] = useState<boolean>(false)
    const {register, handleSubmit,setValue} = useForm<clientRightsType>(
       
        
    )
    const saveData = (data: clientRightsType) => {
        dispatch(changeClientRightsTC(props.payload.teammateId, data))
    }
    const handler = (id: string, value: boolean, field: keyof clientRightsType) => {
        setState(state.map(el => el.id === id ? {...el, value} : el))
        setValue(field, value)
    }
    function checkType(value: any): value is keyof(clientRightsType){
        return Object.keys(props.payload).includes(value)
    }
    const reset = () => {
        
      setState(initState)
    }
    useEffect(() => {
        if(initValues.length){
            const values = state.map(el => el.value).sort((a,b) => a > b ? 1 : -1)
       if(JSON.stringify(values) === JSON.stringify(initValues.sort((a,b) => a > b ? 1 : -1))){
        setEditMode(false)
       } else {
        setEditMode(true)
       }
        }
       
    },[JSON.stringify(state), initValues.length])
   
    useEffect(() => {
        if(appState.successMessageClientRights){
            setEditMode(false)
            setTimeout(() => {
                dispatch(setSuccesMessageClientRightsAC(""))
            },1500)
        }
    },[appState.successMessageClientRights])
    useEffect(() => {
        if(props.payload.id){
            setState(initState)
            let arr:boolean[] = []
            for(let key in props.payload){
                if(checkType(key) && (typeof props.payload[key] === "boolean")){
                   const value =  props.payload[key] as boolean
                   arr.push(value)
                    setValue(key, value)
                }
            }
            setInitValues(arr)
            setValue("id",props.payload.id)
        } 
        if(props.payload.teammateId) setValue("teammateId",props.payload.teammateId)
    },[props.payload.id, props.payload.teammateId, JSON.stringify(props.payload)])
    // useEffect(() => {
    //     setState(data)
    // },[JSON.stringify(props.payload)])
    console.log("init", initValues)
    return(
        <FlexBox onSubmit={handleSubmit(saveData)} as={"form"} direction="column" width="100%" height="100%">
            <FlexBox background="#444" direction="column" justify="center" align="center" width="100%" height="37px" borderRadius="8px">
                <Text color="#fff" size={14} font="mont">Клиенты</Text>
            </FlexBox>
            
                {
                    state.map(el => 
                        <FlexBox marginTop="5px" padding="0 10px" height="36px" background={theme.colors.colorForm} borderRadius="8px" align="center" gap={10} key={el.id} direction="row">
                        <CheckBox
                        variant="primary"
                        value={el.value}
                        render={() => (
                          <input
                            checked={el.value}
                            onChange={(e) => handler(el.id, e.currentTarget.checked,el.field)}
                            type="checkbox"
                          />
                        )}
                      />
                      <FlexBox  justify="start" align="center" height="100%" width="100%"  direction="row">
                      <Text align="start" size={12} font="mont" color="#000">{el.title}</Text>
                      </FlexBox>
                      
                       </FlexBox>
                    )
                }
               {
                editMode && <FlexBox gap={10} height="36px" direction="row" width="100%">
           
                <button onClick={reset} style={{width: "100%", background:0, border: "none", fontSize: 12, fontWeight: 600}}>Сбросить</button>
                <button style={{width: "100%", background:0, border: "none",fontSize: 12,fontWeight: 600,textShadow: "0 2px rgba(0, 0, 0, 0.25)", color: "#5d5ffe"}}>Сохранить</button>
               </FlexBox>
            } 
           <Text size={12} color="green" font="mont">{appState.successMessageClientRights}</Text>
        </FlexBox>
    )
}