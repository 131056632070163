import { useDispatch, useSelector } from "react-redux";
import { queueType } from "../apiTypes/root/queue.type";
import c from "./timeTables.module.css";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import {
  queueRescheduleWindowAC,
  setNewEntryFromQueueStateAC,
  showNewEntryAC,
} from "../store/ModalWindowsReducer";
import { removeQueueTC } from "../store/QueueReducer";
import { tariffType } from "../store/UserReducer";
import { selectedMasterType, selectMasterAC } from "../store/CommonReducers";
import { FlexBox } from "../styles/components/FlexBox";

type propsType = {
  queues: queueType[];
};

const selectUser = (state: AppRootState) => state.user;
const selectUserSuperPro = (state: AppRootState) => state.userProPlusState;
export const Queue = (props: propsType) => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const user = useSelector(selectUser);
  const userProPlusState = useSelector(selectUserSuperPro);
  const showNewEntryFromQueue = (
    workServiceId: string,
    clientId: string,
    queueId: string,
    userId: string
  ) => {
    dispatch(showNewEntryAC({ value: true, fromQueue: true }));
    dispatch(setNewEntryFromQueueStateAC({ workServiceId, clientId, queueId }));
    if (user.user && user.user.tariff === tariffType.SuperPRO) {
      const master = userProPlusState.teammates.teammates.find(
        (u) => u.user.userId === userId
      );
      if (master) {
        const payload: selectedMasterType = {
          masterID: master.user.userId,
          masterName: master.user.firstName,
          avatarUrl: master.user.avatarUrl,
        };
        dispatch(selectMasterAC(payload));
        return;
      }
    }
  };
  return (
    <FlexBox width="100%" direction="column">
      {props.queues.map((q, i) => (
        <div
          key={q.client.id + i}
          style={{
            height: "58px",
            background: "#295CA3",
            color: "white",
            fontSize: "12px",
            borderRadius: "4px",
            margin: "1px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "2px",
            
          }}
        >
          <FlexBox direction="row" 
          width="100%" 
          gap={3}
        //   border="1px solid" 
          marginTop="3px"
          padding="0 5px"
          >
            <button
              onClick={() => dispatch(removeQueueTC(q.id))}
              className={c.close}
            ></button>
            <button
              onClick={() =>
                dispatch(
                  queueRescheduleWindowAC({
                    value: true,
                    month: q.month,
                    year: q.year,
                    workServiceId: q.workService.name,
                    clientId: q.client.id,
                    id: q.id,
                  })
                )
              }
              className={c.folding}
            ></button>
            <button
              onClick={() =>
                showNewEntryFromQueue(
                  q.workService.name,
                  q.client.id,
                  q.id,
                  q.userId
                )
              }
              className={c.unfolding}
            ></button>
          </FlexBox>
          <FlexBox marginTop="3px" direction="row">
          <span
            style={{ fontSize: "10px" }}
          >{`${q.client.surname} ${q.client.name}`}</span>
          </FlexBox>
         <FlexBox  direction="row" >
         <span style={{ fontSize: "10px" }}>{q.client.phone}</span>

         </FlexBox>
        </div>
      ))}
    </FlexBox>
  );
};
