import { useEffect, useId, useState } from "react"
import { timeTableRightsType } from "../../apiTypes/root/userProPlus.type"
import { CheckBox } from "../../styles/components/CheckBox"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"
import { useForm } from "react-hook-form"
import { theme } from "../../styles/components/Theme.styled"
import { thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch } from "react-redux"
import { changeTimeTableRightsTC } from "../../store/UserProPlusReducer"

type propsType = {
    payload: timeTableRightsType 
}

export const TimeTableRights = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
    const data: {id: string, field: keyof timeTableRightsType, title: string,value: boolean}[] = [
        {id: useId(), field: "candReadTeammateNotes" ,title: "Показывать чужие записи", value: props.payload.candReadTeammateNotes},
        {id: useId(), field: "canAddAndEditOwn" ,title: "Добавление-Редактирование (своих)", value: props.payload.canAddAndEditOwn},
        // {id: useId(), field: "candReadTeammateNotes" ,title: "Удаление (своих)", value: props.payload.},
        {id: useId(), field: "isAdmin" ,title: "Администратор расписания", value: props.payload.isAdmin},
        {id: useId(), field: "canEditPastTime" ,title: "Редактировать прошедшее время", value: props.payload.canEditPastTime},
        {id: useId(), field: "canDeleteOwn" ,title: "Удалять собственные записи", value: props.payload.canDeleteOwn},
    ]
    const [initValues, setInitValues] = useState<boolean[]>([])
    const [state, setState] = useState<Array<{id: string, field: keyof timeTableRightsType, title: string,value: boolean}>>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const {register, handleSubmit, setValue, formState} = useForm< timeTableRightsType>(
        {
            // defaultValues: {
            //     canAddAndEditOwn: props.payload.canAddAndEditOwn, 
            //     candReadTeammateNotes: props.payload.candReadTeammateNotes, 
            //     canEditPastTime: props.payload.canEditPastTime, 
            //     isAdmin: props.payload.isAdmin,
            //      id: props.payload.id, 
            //      teammateId: props.payload.teammateId
            // }
        }
        
    )
    const saveData = (data: timeTableRightsType) => {
        dispatch(changeTimeTableRightsTC(props.payload.teammateId, data))
    }
    const handler = (id: string, value: boolean, field: keyof timeTableRightsType) => {
        setState(state.map(el => el.id === id ? {...el, value} : el))
        setValue(field, value)
    }
    function checkType(value: any): value is keyof(timeTableRightsType){
        return Object.keys(props.payload).includes(value)
    }
    const reset = (e:any) => {
        e.preventDefault()
      setState(data)
      for(let key in props.payload){
       if(checkType(key)) setValue(key, props.payload[key])
      }
    }
    useEffect(() => {
       const values = state.map(el => el.value)
       if(JSON.stringify(values) === JSON.stringify(initValues)){
        setEditMode(false)
       } else {
        setEditMode(true)
       }
    },[JSON.stringify(state), state.length, initValues.length])
    useEffect(() => {
        if(props.payload.id){
            setState(data)
            for(let key in props.payload){
                if(checkType(key) && (typeof props.payload[key] === "boolean")){
                   const value =  props.payload[key]
                    setInitValues(prev => [...prev,Boolean(value)])
                    setValue(key, value)
                }
            }
            setValue("id",props.payload.id)
        } 
        if(props.payload.teammateId) setValue("teammateId",props.payload.teammateId)
    },[props.payload.id, props.payload.teammateId])

    useEffect(() => {
        return () => {
            setEditMode(false)
        }
    },[])
// console.log("props", props.payload, state)
    return(
        <FlexBox onSubmit={handleSubmit(saveData)} as={"form"} direction="column" width="100%" height="100%">
            <FlexBox background="#444" direction="column" justify="center" align="center" width="100%" height="37px" borderRadius="8px">
                <Text color="#fff" size={14} font="mont">Расписание</Text>
            </FlexBox>
            
                {
                    state.map(el => 
                        <FlexBox marginTop="5px" padding="0 10px" height="36px" background={theme.colors.colorForm} borderRadius="8px" align="center" gap={10} key={el.id} direction="row">
                        <CheckBox
                        variant="primary"
                        value={el.value}
                        render={() => (
                          <input
                            checked={el.value}
                            onChange={(e) => {e.preventDefault(); handler(el.id, e.currentTarget.checked, el.field)}}
                            type="checkbox"
                            
                          />
                        )}
                      />
                      <Text size={14} font="mont" color="#000">{el.title}</Text>
                       </FlexBox>
                    )
                }
               {
                editMode && <FlexBox gap={10} height="36px" direction="row" width="100%">
           
                <button onClick={reset} style={{width: "100%", background:0, border: "none", fontSize: 12, fontWeight: 600}}>Сбросить</button>
                <button style={{width: "100%", background:0, border: "none",fontSize: 12,fontWeight: 600,textShadow: "0 2px rgba(0, 0, 0, 0.25)", color: "#5d5ffe"}}>Сохранить</button>
               </FlexBox>
            } 
           
        </FlexBox>
    )
}