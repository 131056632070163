
import { apiClients } from "../apiServer/apiClients";
import { apiUserSuperPro } from "../apiServer/apiUserProPlus";
import { TCreateTeammateForm } from "../apiTypes/formTypes/fillModeFormTypes";
import { createNoteForNewUserType, noteInputType } from "../apiTypes/responses/notesResponsesType";
import { freeTimeForTeamType, workTimeForTeamType } from "../apiTypes/responses/workTimeResponseType";
import {  clientRightsType, settingsRightsType, taemmateForTimeTableType, teammateProSettingsType, teamMateViewType,  timeTableRightsType,  updateTeammateRightsInputType, userProPlusStateType } from "../apiTypes/root/userProPlus.type";

import { setEnableSwitchAC, setIsLoadingAC, setSuccesMessageClientRightsAC, setSuccesMessageSettingsRightsAC } from "./AppReducer";
import { addClientAC, getClientsForProPlus } from "./ClientsReducer";
import { TimeTablePeriod } from "./CommonReducers";
import { addNewClientCardAC, editCardAC, editNoteWindowAC, showCreateTeammateAC, showNewEntryAC } from "./ModalWindowsReducer";
import {  notesStateType, Status } from "./NotesReducer";
import { thunkActionRootType, thunkDispatchRootType } from "./storeRedux";

const initState:userProPlusStateType = {
    teammates: {
        teamId: "",
        teammates: []
    },
    
    targetTeammate: {
        id: "",
        isAccepted: false,
        isAdmin: false,
        isOwner: false,
        user: {
            userId: "",
            avatarUrl: "",
            firstName: "",
            lastName: "",
            thirdName: "",
            email: "",
            phoneNumber: "",
            color: "",
            registerDate: "",
            login: ""
        },
        clientRights: {
            id: "",
            teammateId: "",
            canAddWork: false,
            canDeleteEverybody: false,
            candDeleteOwnClients: false,
            canEditAllClients: false,
            canEditWork: false,
            canReadTeammateClients: false,
            canSeeBalanceTeammatesClients: false,
            canSeePhoneTeammatesClients: false,
            canTopUpBalanceTeammatesClients: false
        },
        timeTableRights: {
            id: "",
            teammateId: "",
            canAddAndEditOwn: false,
            candReadTeammateNotes: false,
            canEditPastTime: false,
            isAdmin: false,
            canDeleteOwn: false
        },
        settingsRights: {
            id: "",
            teammateId: "",
            priceListManage: false,
            sendingManage: false,
            payManage: false,
            canEditTeammates: false
        }
    },
    teammateWorkTimes: {"": []},
    teammateFreeTimes: {"": []},
    // {teamId: "",teammates: [],owner: {userId: "",lastName: "",firstName: "",avatarUrl: ""}},
    renderTimes: [],
    notesForTeam: [],
    activeDate: "",
    teammatesForTimeTable: {
        page: 1,
        pagesCount: 1,
        pageSize: 6,
        teammates: [],
        totalCount: 0

    }
   
} 

export  type userProPlusAT =  getTeammateAT | changeTeamMateRighAT |  getWorkTimesTeammateAT | setRenderTimesAT | getFreeTimesTeammateAT  | getNotesForWeekAT
        | addNoteForWeekAT  | setActiveDateForTimeTableAT | makeAdminTeammateAT  | changeNoteStatusForWeekAT | deleteNoteForWeekAT
        | editNoteAT | getTeammatesForTimeTableAT | changePageForTeammateAT | setTargetTeammateAT | changeTimeTableRightsAT | changeClientRightsAT
        | changeSettingsRightsAT
    
                                

type getTeammateAT = {
    type: "USER-PRO-PLUS/GET-TEAMMATE",
    users: teamMateViewType
}

type changeTeamMateRighAT = {
    type: "USER-PRO-PLUS/CHANGE-TEAMMATE-RIGHT",
    data: updateTeammateRightsInputType,
    userId: string
}


type getWorkTimesTeammateAT = {
    type: "USER-PRO-PLUS/GET-WORK-TIMES",
    payload: workTimeForTeamType
}
type getFreeTimesTeammateAT = {
    type: "USER-PRO-PLUS/GET-FREE-TIMES",
    payload: freeTimeForTeamType
}
type setRenderTimesAT = {
    type: "USER-PRO-PLUS/SET-RENDER-TIMES",
    payload: string[]
}


type getNotesForWeekAT = {
    type: "USER-PRO-PLUS/GET-NOTES-FOR-WEEK",
    payload: notesStateType[]
}
type addNoteForWeekAT = {
    type: "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK",
    payload: notesStateType
}

type setActiveDateForTimeTableAT = {
    type: "USER-PRO-PLUS/SET-ACTIVE-DATE",
    activeDate: string
}
type makeAdminTeammateAT = {
    type: "USER-PRO-PLUS/MAKE-ADMIN",
    id: string,
    value: boolean
}



type changeNoteStatusForWeekAT = {
    type: "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK",
    payload: {
        id: string,
        status: Status
    }
}
type deleteNoteForWeekAT = {
    type: "USER-PRO-PLUS/DELETE-NOTE",
    id: string
}
type editNoteAT = {
    type: "USER-PRO-PLUS/EDIT-NOTE",
    payload: notesStateType
}
type getTeammatesForTimeTableAT = {
    type: "USER-PRO/GET-TEAMMATES-TIMETABLE",
    payload: taemmateForTimeTableType
}
type changePageForTeammateAT = {
    type: "USER-PRO/CHANGE-PAGE-TEAMMATE-TIMETABLE",
    page: number
}
type setTargetTeammateAT = {
    type: "USER-PRO/SET-TARGET-TEAMMATE",
    payload: teammateProSettingsType 
}
type changeTimeTableRightsAT = {
    type: "USER-PRO/CHANGE-TIMETABLE-RIGHTS",
    payload: Omit<timeTableRightsType, "id" | "teammateId">,
    teammateId: string
}
type changeClientRightsAT = {
    type: "USER-PRO/CHANGE-CLIENT-RIGHTS",
    payload: Omit<clientRightsType, "id" | "teammateId">,
    teammateId: string
}
type changeSettingsRightsAT = {
    type: "USER-PRO/CHANGE-SETTINGS-RIGHTS",
    payload: Omit<settingsRightsType, "id" | "teammateId">,
    teammateId: string
}

export const UserProPlusReducer = (state: userProPlusStateType = initState, action: userProPlusAT):userProPlusStateType => {
    switch(action.type){
        case "USER-PRO-PLUS/GET-TEAMMATE":
            return {...state, teammates: {...action.users, teammates: action.users.teammates.map(t => ({...t,  user: {...t.user, avatarUrl: t.user.avatarUrl}}))} }
        case "USER-PRO-PLUS/GET-WORK-TIMES":
            return {...state,teammateWorkTimes: action.payload}
        case "USER-PRO-PLUS/SET-RENDER-TIMES":
            return {...state, renderTimes: action.payload}
        case "USER-PRO-PLUS/GET-FREE-TIMES":
            return {...state, teammateFreeTimes: action.payload}
       
       case "USER-PRO-PLUS/EDIT-NOTE":
        return {...state, notesForTeam: [...state.notesForTeam, action.payload]}
        case "USER-PRO-PLUS/GET-NOTES-FOR-WEEK":
            return {...state, notesForTeam: action.payload}
       
        case "USER-PRO-PLUS/SET-ACTIVE-DATE":
            return {...state, activeDate: action.activeDate}
        case "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK":
           
            return {...state, notesForTeam: [...state.notesForTeam, action.payload]}
           
        case "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK":
            return {...state, notesForTeam: state.notesForTeam.map(n => n.id === action.payload.id ? ({...n,status: action.payload.status}) : n)}
        case "USER-PRO-PLUS/MAKE-ADMIN":
            return {...state, teammates: {...state.teammates, teammates:  state.teammates.teammates.map( t => t.id === action.id ? ({...t,isAdmin: action.value}) : t)}}
       
        case "USER-PRO-PLUS/DELETE-NOTE":
            return {...state,notesForTeam: state.notesForTeam.filter(n => n.id !== action.id)}
        case "USER-PRO/GET-TEAMMATES-TIMETABLE":
            return {...state, teammatesForTimeTable: action.payload}
        case "USER-PRO/CHANGE-PAGE-TEAMMATE-TIMETABLE":
            return {...state, teammatesForTimeTable: {...state.teammatesForTimeTable, page: action.page}}
        case "USER-PRO/SET-TARGET-TEAMMATE":
            return {...state, targetTeammate: action.payload}
        case "USER-PRO/CHANGE-TIMETABLE-RIGHTS":
            return {...state, targetTeammate: action.teammateId === state.targetTeammate.id ? ({...state.targetTeammate, timeTableRights: {...state.targetTeammate.timeTableRights, ...action.payload}}) : state.targetTeammate,teammates: {...state.teammates,teammates: state.teammates.teammates.map(t => t.id === action.teammateId ? ({...t, timeTableRights: {...t.timeTableRights,...action.payload}}) : t)}}
        case "USER-PRO/CHANGE-CLIENT-RIGHTS":
            return {...state, targetTeammate: action.teammateId === state.targetTeammate.id ? ({...state.targetTeammate, clientRights: {...state.targetTeammate.clientRights, ...action.payload}}) : state.targetTeammate,teammates: {...state.teammates,teammates: state.teammates.teammates.map(t => t.id === action.teammateId ? ({...t, clientRights: {...t.clientRights,...action.payload}}) : t)}}
        case "USER-PRO/CHANGE-SETTINGS-RIGHTS":
            return {...state, targetTeammate: action.teammateId === state.targetTeammate.id ? ({...state.targetTeammate, settingsRights: {...state.targetTeammate.settingsRights, ...action.payload}}) : state.targetTeammate,teammates: {...state.teammates,teammates: state.teammates.teammates.map(t => t.id === action.teammateId ? ({...t, settingsRights: {...t.settingsRights,...action.payload}}) : t)}}
        default: 
            return state
    }
}

const getAllTeammatesForAdminAC = (data: teamMateViewType):getTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-TEAMMATE",users: data}
}
const changeTeamMateRighAC = (data: updateTeammateRightsInputType,userId: string):changeTeamMateRighAT => {
    return {type: "USER-PRO-PLUS/CHANGE-TEAMMATE-RIGHT",data,userId}
}

const setRenderTimesForTeamAC = (payload: Array<string>):setRenderTimesAT => {
    return {type: "USER-PRO-PLUS/SET-RENDER-TIMES",payload}
}
const getWorkTimesForTeamAC = (payload: workTimeForTeamType):getWorkTimesTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-WORK-TIMES",payload}
}
const getFreeTimesForTeamAC = (payload: freeTimeForTeamType):getFreeTimesTeammateAT => {
    return {type: "USER-PRO-PLUS/GET-FREE-TIMES",payload}
}

const getNotesForWeekAC = (payload: notesStateType[]):getNotesForWeekAT => {
    return {type: "USER-PRO-PLUS/GET-NOTES-FOR-WEEK",payload}
}
const addNoteForWeekAC = (payload: notesStateType):addNoteForWeekAT => {
    return {type: "USER-PRO-PLUS/ADD-NOTES-FOR-WEEK",payload}
}

const makeAdminTeammateAC = (id: string, value: boolean):makeAdminTeammateAT => {
    return {type: "USER-PRO-PLUS/MAKE-ADMIN",id, value}
}


const removeNoteForWeekAC = (id: string):deleteNoteForWeekAT => {
    return {type: "USER-PRO-PLUS/DELETE-NOTE",id}
}
const editNoteAC = (payload: notesStateType):editNoteAT => {
    return {type: "USER-PRO-PLUS/EDIT-NOTE",payload}
}
const getTeammatesForTimeTableAC = (payload: taemmateForTimeTableType):getTeammatesForTimeTableAT => {
    return {type: "USER-PRO/GET-TEAMMATES-TIMETABLE", payload}
}
const setTargetTeammateAC = (payload: teammateProSettingsType):setTargetTeammateAT => {
    return {type: "USER-PRO/SET-TARGET-TEAMMATE",payload}
}

const changeTimeTableRightsAC = (teammateId: string, payload: Omit<timeTableRightsType, "id" | "teammateId">):changeTimeTableRightsAT => {
    return {type: "USER-PRO/CHANGE-TIMETABLE-RIGHTS", teammateId, payload}
}
const changeClientRightsAC = (teammateId: string, payload: Omit<clientRightsType, "id" | "teammateId">):changeClientRightsAT => {
    return {type: "USER-PRO/CHANGE-CLIENT-RIGHTS", teammateId, payload}
}
const changeSettingsRightsAC = (teammateId: string, payload: Omit<settingsRightsType, "id" | "teammateId">):changeSettingsRightsAT => {
    return {type: "USER-PRO/CHANGE-SETTINGS-RIGHTS", teammateId, payload}
}

export const changePageForTeammateAC = (page: number): changePageForTeammateAT => {
    return {type: "USER-PRO/CHANGE-PAGE-TEAMMATE-TIMETABLE",page}
}
export const changeNoteStatusForWeekAC = (payload: {id: string,status: Status}):changeNoteStatusForWeekAT => {
    return {type: "USER-PRO-PLUS/CHANGE-NOTE-STATUS-WEEK",payload}
}
export const setActiveDateForTimeTableAC = (activeDate: string):setActiveDateForTimeTableAT => {
    return {type: "USER-PRO-PLUS/SET-ACTIVE-DATE",activeDate}
}
export const createTeamMateTC = (data:  TCreateTeammateForm): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.createTeammate(data)
        dispatch(showCreateTeammateAC(false))
        dispatch(getAllTeammatesForAdminTC())
    } catch (error ) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const getAllTeammatesForAdminTC = ():thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getAllTeammatesForAdmin()
        dispatch(getAllTeammatesForAdminAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}


export const changeTeamMateRighsTC = (data: updateTeammateRightsInputType, userId: string):thunkActionRootType => async dispatch => {
    dispatch(setEnableSwitchAC(false))
    try {
        const res = await apiUserSuperPro.changeTeamMateRights(data,userId)
        dispatch(changeTeamMateRighAC(data,userId))
    } catch (error) {
        
    } finally {
        dispatch(setEnableSwitchAC(true))
    }
}



export const getWorkTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getWorkTimes(date)
        dispatch(getWorkTimesForTeamAC(res.data))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}
export const getRenderTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getRenderTime(date)
        dispatch(setRenderTimesForTeamAC(res.data))
    } catch (error) {
        
    }
}
export const getFreeTimesForTeamTC = (date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getFreeTimes(date)
        dispatch(getFreeTimesForTeamAC(res.data))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}



export const getTeammatesNotesForWeekTC = (masterId: string, start: string, end: string):thunkActionRootType => async dispatch =>  {
   dispatch(setIsLoadingAC(true))
    try {
            const res = await apiUserSuperPro.getNotesForWeek(masterId,start,end)
            dispatch(getNotesForWeekAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
// export const getNoteForTeamInDayTC = (date: string):thunkActionRootType => async dispatch => {
//     dispatch(setIsLoadingAC(true))
//     try {
//         const res = await apiUserSuperPro.getTeamNotes(date)
//         dispatch(getNotesForWeekAC(res.data))
//     } catch (error) {
        
//     } finally {
//         dispatch(setIsLoadingAC(false))
//     }
// }

export const createTeammateNoteTC = (data: noteInputType, userId: string, period: TimeTablePeriod, masterId: string, isOwner: boolean, date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.createNote(data, userId)
        
            dispatch(addNoteForWeekAC(res.data))
       
     
    } catch (error) {
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}


export const makeAdminTeammateTC = (id: string, value: boolean):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.makeAdmin(id, value)
        dispatch(makeAdminTeammateAC(id, value))
    } catch (error) {
        
    }
    finally {
        dispatch(setIsLoadingAC(false))

    }
}




export const removeNoteForWeekTeamTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.removeNoteForWeek(id)
        dispatch(removeNoteForWeekAC(id))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))

}

export const editNotForTeamTC = (data: noteInputType, id: string, masterId: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.editNote(data,id, masterId)
       dispatch(editNoteAC(res.data))
       dispatch(removeNoteForWeekAC(id))
       dispatch(editNoteWindowAC({value: false, note: null}))
    } catch (error) {
        
    }
    dispatch(setIsLoadingAC(false))
}




export const addNewClientAndNewEntryTC = (client: { photo: any, name: string, surname: string, patronymic: string, phone: string | null, gender: string, birthday: Date | null, agreement: boolean, waAgreement: boolean}, note: createNoteForNewUserType & {serviceId: string}): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        // const res = await apiUserSuperPro.creteClientAndNewEntry(client, note)
        // dispatch(addNoteForWeekAC(res.data))
        const newClient = await apiClients.createClient(client.photo,client.name,client.surname,client.patronymic,client.phone,client.gender,client.birthday,client.agreement,client.waAgreement)
        //@ts-ignore
        const newEntry = await apiUserSuperPro.createNote({clientAvatar: newClient.data.photoURL,serviceId: note.service,userId: note.userId,time: note.time, date: note.date, duration: note.duration, clientId: newClient.data.id, surname: newClient.data.surname, name: newClient.data.name, phone: newClient.data.phone as string,comments: note.comments}, note.userId)
        dispatch(addNoteForWeekAC(newEntry.data))
        // dispatch(getClientsForProPlus())
        const {id,name,surname,patronymic,phone,photoURL,owner,userId,gender,birthday,agreement,waAgreement,addedDate,comments,spentMoney,visitCount} = newClient.data
        dispatch(addClientAC(owner,id,photoURL,name,surname,patronymic,phone,gender,birthday,agreement,addedDate,comments,userId,waAgreement,spentMoney,visitCount))
    } catch (error) {
        
    } finally {
        dispatch( addNewClientCardAC(false))
        dispatch(showNewEntryAC({value: false, fromQueue: false}))
        dispatch(setIsLoadingAC(false))
    }
}


export const getTeammatesForTimeTableTC = (page: number, pageSize: number, date: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getTeammatesForTimeTable(page,pageSize, date)
        dispatch(getTeammatesForTimeTableAC(res.data.teammates))
        dispatch(getNotesForWeekAC(res.data.notes))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}

export const setTargetTeammateTC = (id: string):thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const res = await apiUserSuperPro.getTeammateById(id)
        dispatch(setTargetTeammateAC(res.data))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))

    }
}

export const changeTimeTableRightsTC = (teammateId: string, data: timeTableRightsType): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const {canAddAndEditOwn, canEditPastTime, candReadTeammateNotes, isAdmin,canDeleteOwn} = data
        const res = await apiUserSuperPro.changeTimeTableRights(teammateId, {canAddAndEditOwn, candReadTeammateNotes, canEditPastTime, isAdmin, canDeleteOwn})
        dispatch(changeTimeTableRightsAC(teammateId,{canAddAndEditOwn, candReadTeammateNotes, canEditPastTime, isAdmin,canDeleteOwn} ))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const changeClientRightsTC = (teammateId: string, data: clientRightsType): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const {canAddWork,canDeleteEverybody,canEditAllClients,canEditWork,canReadTeammateClients,canSeeBalanceTeammatesClients,canSeePhoneTeammatesClients,canTopUpBalanceTeammatesClients,candDeleteOwnClients} = data
        const res = await apiUserSuperPro.changeClientRights(teammateId, {canAddWork,candDeleteOwnClients,canDeleteEverybody,canEditAllClients,canEditWork,canReadTeammateClients,canSeeBalanceTeammatesClients,canSeePhoneTeammatesClients,canTopUpBalanceTeammatesClients})
        dispatch(changeClientRightsAC(teammateId, {canAddWork,candDeleteOwnClients,canDeleteEverybody,canEditAllClients,canEditWork,canReadTeammateClients,canSeeBalanceTeammatesClients,canSeePhoneTeammatesClients,canTopUpBalanceTeammatesClients}))
        dispatch(setSuccesMessageClientRightsAC("Настройки успешно изменены!"))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}
export const changeSettingsRightsTC = (teammateId: string, data: settingsRightsType): thunkActionRootType => async dispatch => {
    dispatch(setIsLoadingAC(true))
    try {
        const {payManage, priceListManage, canEditTeammates,sendingManage} = data
        const res = await apiUserSuperPro.changeSettingsRights(teammateId, {sendingManage,payManage,priceListManage,canEditTeammates})
        dispatch(changeSettingsRightsAC(teammateId, {payManage,priceListManage,canEditTeammates,sendingManage}))
        dispatch(setSuccesMessageSettingsRightsAC("Настройки успешно изменены!"))
    } catch (error) {
        
    } finally {
        dispatch(setIsLoadingAC(false))
    }
}