import ReactDOM from "react-dom"
import { AppRootState } from "../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { ModalLayer } from "../styles/components/ModalLayer"
import { CreatingTeammateWindow } from "./child/CreatingTeammateWindow"
import { SearchTeamMateWindow } from "./child/SearchTeamMateWindow"
import { resetModalStateAC } from "../store/ModalWindowsReducer"
import { RemoveNoteWindow } from "./child/RemoveNoteWindow"
import { NewEntry } from "./child/NewEntry"
import { AddNewClientCard } from "./child/AddNewClientCard"
import { NewClient } from "./child/NewClient"
import { RemoveCard } from "./child/RemoveCard"
import { TimePickers } from "../styles/components/TimePicker"
import { CalendarEdition } from "./child/CalendarEdition"
import { Box } from "../styles/components/Box"
import { PayWindow } from "./child/PayWindow"
import { QrCodeWindow } from "./child/QrCodeWindow"
import { CreateCategory } from "./child/CreateCategory"
import { CreateWorkWindow } from "./child/CreateWorkWindow"
import { RemoveWorkServiceWindow } from "./child/RemoveWorkServiceWindow"
import { EditWorkWindow } from "./child/EditWorkWindow"
import { EditNote } from "./child/EditNote"
import { QueueWindow } from "./child/QueueWindow"
import { ClinetBalanceWindow } from "./child/ClientBalanceWindow"
import { RemindWindow } from "./child/RemindWindow"
import { WorkClientWindow } from "./child/ClientWorkWindow"
import { EditClient } from "./child/EditClient"
import { SearchWindow } from "../searchContent/SearchWindow"
import { RemoveWorkCategoryWindow } from "./child/RemoveWorkCategory"
import { EditWorkCategoryWindow } from "./child/EditWorkCategoryWindow"
import { QueueRescheduleWindow } from "./child/QueueRescheduleWindow"
import { tariffType } from "../store/UserReducer"
import { SearchWindowForProPlus } from "../searchContent/SearchWindowForProPlus"
import { WaSendWindow } from "./child/WA-send-window"



const selectModalWindowState = (state: AppRootState) => state.ModalWindowReducer
const selectUser = (state: AppRootState) => state.user
export const ModalComponents = () => {
    const dispatch = useDispatch()
    const modalState = useSelector(selectModalWindowState)
    const user = useSelector(selectUser)
    const isComponent =   modalState.creatingTeammateWindow || modalState.modeModalTeamMate
        || modalState.removeNoteWindow || modalState.newEntryShow.value || modalState.editSessionWindow || modalState?.addNewClientCard
        || modalState.newClientWindow.value || modalState.removeClientWindow.value 
        || modalState.timePickerWindow || modalState.calendarEditionDates || modalState.payWindow || modalState.qrCodeWindow
        || modalState.createCategory || modalState.createWorkWindow || modalState.removeWorkState.value || modalState.editWorkServiceState.value
        || modalState.editNoteState.value || modalState.queue.value || modalState.clientBalanceWindow.value || modalState.remindWindowState.value
        || modalState.clientWorkWindowState.value || modalState.editClient.value || modalState.searchWindow || modalState.deleteWorkCategoryState.value
        || modalState.editWorkCategoryState.value || modalState.queueRescheduleState.value || modalState.adminPayWindow.value || modalState.searchTeammateWindow
        || modalState.waWindow.value

    const reset = () => dispatch(resetModalStateAC())
    return ReactDOM.createPortal(
        <>
            {
                isComponent &&

                <ModalLayer onClick={reset} />


            }
            {
                modalState.creatingTeammateWindow && <CreatingTeammateWindow />
            }
            {
                modalState.modeModalTeamMate && <SearchTeamMateWindow />
            }
            {
                modalState.removeNoteWindow && <RemoveNoteWindow />
            }
            {
                modalState?.newEntryShow.value && !modalState.addNewClientCard &&
                <NewEntry />
            }
           
            {
                modalState?.addNewClientCard &&
                 <Box bottom={"unset"} zIndex={27} position="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" height="auto" width="auto">
                    <AddNewClientCard />
                 </Box>

            }
            {modalState.newClientWindow.value && <NewClient />}
            {modalState.removeClientWindow.value && <RemoveCard />}

            {
                modalState.timePickerWindow && <TimePickers />
            }
            {modalState?.calendarEditionDates && !modalState.timePickerWindow &&
                <CalendarEdition />
            }
            {
                modalState.payWindow && !modalState.qrCodeWindow && <PayWindow/>
            }
            {
                modalState.qrCodeWindow && <QrCodeWindow/>
            }
            {
                modalState.createCategory && <CreateCategory/>
            }
            {
                modalState.createWorkWindow && <CreateWorkWindow/>
            }

            {
                modalState.removeWorkState.value && <RemoveWorkServiceWindow/>
            }

            {
                modalState.editWorkServiceState.value && <EditWorkWindow/>
            }

            {
                modalState.editNoteState.value && !modalState.queue.value && !modalState.clientBalanceWindow.value && !modalState.waWindow.value && <EditNote/>
            }

            {
                modalState.queue.value && <QueueWindow/>
            }

            {
                modalState.clientBalanceWindow.value && <ClinetBalanceWindow/>
            }

            {
                modalState.remindWindowState.value && <RemindWindow/>
            }
            {
                modalState.clientWorkWindowState.value && <WorkClientWindow/>
            }
            {
                modalState.editClient.value && <EditClient/>
            }
            {
                modalState.searchWindow && user.user?.tariff !== tariffType.SuperPRO &&  <SearchWindow/>
            }
            {
                modalState.searchWindow && user.user?.tariff === tariffType.SuperPRO &&  <SearchWindowForProPlus/>
            }
            {
                modalState.deleteWorkCategoryState.value && <RemoveWorkCategoryWindow/>
            }
            {
                modalState.editWorkCategoryState.value && <EditWorkCategoryWindow/>
            }
            {
                modalState.queueRescheduleState.value && <QueueRescheduleWindow/>
            }
            {
                modalState.adminPayWindow.value && <PayWindow/>
            }
            {
                modalState.searchTeammateWindow && <SearchTeamMateWindow/>
            }
            {
                modalState.waWindow.value && <WaSendWindow/>
            }

        </>, document.body
        //
    )
}

