import { css } from "styled-components";
import { styled } from "styled-components";

type propsType = {
    width?: string,
    free?: boolean,
    duration?: number,
}

export const TimeTableCellContainer = styled.div<propsType>`
    width: ${props => props.width ? props.width : "140px"} ;
    height: 100%;
    position: relative;
    display: flex;
    ${props => props.free && css<propsType>`
        background: repeating-linear-gradient(45deg, white, white 10px, #f5f5f5 10px, #f5f5f5 20px);
        ${props => props.duration && css<propsType>`
    height: calc(${props => Number(props.duration) / 60 * 100 }%);
    position: absolute;
    `}
    `};
   
` 