import { useDispatch, useSelector } from "react-redux";
import { ModalWindowStateType } from "../store/ModalWindowsReducer";
import { stateUserType } from "../store/UserReducer";
import { AppRootState, thunkDispatchRootType } from "../store/storeRedux";
import { useEffect, useState } from "react";
import { Table } from "../styles/components/Table";
import { TableHeader } from "../styles/components/TableHeader";
import { Column } from "../styles/components/Column";
import { TableRowBox } from "../styles/components/TableRowBox";
import { TableRow } from "../styles/components/TableRow";
import { Cell } from "../styles/components/Cell";
import { Avatar } from "../styles/components/Avatar";
import { DragDown } from "../styles/components/DragDown";
import { FlexBox } from "../styles/components/FlexBox";
import { Box } from "../styles/components/Box";
import {
  getAllTeammatesForAdminTC,
  makeAdminTeammateTC,
} from "../store/UserProPlusReducer";
import { SuperButton } from "../styles/components/SuperButton";
import { RightsType, teammateProSettingsType } from "../apiTypes/root/userProPlus.type";
import { Text } from "../styles/components/Text";
import { theme } from "../styles/components/Theme.styled";
import { NavLink } from "react-router-dom";
import { CheckBox } from "../styles/components/CheckBox";
import { Icon } from "../styles/components/Icon";

const selectUserProPluseState = (state: AppRootState) => state.userProPlusState;
const userState = (state: AppRootState) => state.user;

export const TeamMates = () => {
  const dispatch: thunkDispatchRootType = useDispatch();
  const [teammate, setTeammate] = useState<null | teammateProSettingsType>(null)
  const userProPlusState = useSelector(selectUserProPluseState);
  const user = useSelector(userState);
  const { teammates } = userProPlusState;
  const columnItems = [
    { title: "ФИО" },
    { title: "Права" },
    // {title: "Расписание"},
    // {title: "Аналитика"},
    // {title: "Настройки"},
    { title: "Действие" },
    { title: "Администратор" },
  ];
const adminHandler = (id: string,value: boolean) => {
  dispatch(makeAdminTeammateTC(id,value))
}
  const checkOwner = (userId: string): boolean => {
    const owner = teammates.teammates.find((t) => t.isOwner);
    if (!owner) return false;
    const userIsOwner = owner.user.userId === user.user?.id;
    return userIsOwner;
  };

  useEffect(() => {
    if (userProPlusState.teammates.teammates.length) {
      const me = userProPlusState.teammates.teammates.find(
        (t) => t.user.userId === user.user?.id
      );
      if (me) {
        setTeammate(me);
      }
    }
  }, [userProPlusState.teammates.teammates.length]);

  return (
    <Box
      style={{ border: "1px solid green" }}
      overFlowY="hidden"
      direction="row"
      height="100%"
      width="100%"
    >
      <Table style={{ border: "1px solid red" }}>
        <TableHeader type="static" offset="small">
          {columnItems.map((el) => (
            <Cell variant="simple">
              <Column component="any">{el.title}</Column>
            </Cell>
          ))}
        </TableHeader>
        <TableRowBox
          style={{
            border: "2px solid yellow",
            overflowY: "auto",
            height: "inherit",
          }}
        >
          {teammates.teammates.map((el) =>
            el.user.userId === user.user?.id ? (
              <></>
            ) : (
              <TableRow>
                <Cell variant="simple">
                  <Avatar src={el.user.avatarUrl} size="small" />
                  <FlexBox marginLeft="7px" direction="row" gap={5}>
                    <Text
                      transform="capitalize"
                      color={theme.colors.fonts.main}
                      size={12}
                      font="mont"
                    >
                      {el.user.lastName}
                    </Text>
                    <Text
                      transform="capitalize"
                      color={theme.colors.fonts.main}
                      size={12}
                      font="mont"
                    >
                      {el.user.firstName}
                    </Text>
                    <Text
                      transform="capitalize"
                      color={theme.colors.fonts.main}
                      size={12}
                      font="mont"
                    >
                      {el.user.thirdName}
                    </Text>
                  </FlexBox>
                </Cell>
                <Cell variant="simple">
                  {
                    teammate?.settingsRights.canEditTeammates || teammate?.isOwner
                    ? <NavLink to={`/rights/${el.id}`}>ред.</NavLink>
                    : <Text size={12} font="mont" color={theme.colors.fonts.main}>ред.</Text>
                  }
                  
                </Cell>
                <Cell variant="simple">
                    <FlexBox direction="row" gap={5}>
                    <SuperButton disabled = {!teammate?.settingsRights.canEditTeammates && !teammate?.isOwner} varinat="sort" onClick={(e) => {}}>
                    <Icon svgID="edit" width={18} height={16} />
                  </SuperButton>
                  <SuperButton disabled = {!teammate?.settingsRights.canEditTeammates && !teammate?.isOwner} varinat="sort" onClick={(e) => {}}>
                    <Icon svgID="delete" width={18} height={16} />
                  </SuperButton>
                    </FlexBox>
                  
                </Cell>

                <Cell variant="action">
                  <CheckBox
                    variant="primary"
                    value={el.isAdmin}
                    render={() => (
                      <input
                      disabled = {!teammate?.settingsRights.canEditTeammates && !teammate?.isOwner}
                        checked={el.isAdmin}
                        onChange={(e) => {adminHandler(el.id, e.currentTarget.checked)}}
                        type="checkbox"
                      />
                    )}
                  />
                </Cell>
              </TableRow>
            )
          )}
        </TableRowBox>
      </Table>
    </Box>
  );
};
