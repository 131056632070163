import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import userInfoStyles from "./userInfoStyles";
import { AppRootState } from "../store/storeRedux";
import { FlexBox } from "../styles/components/FlexBox";
import { userInfoType, setDataUserTC } from "../store/AuthReducer";

type companyPropsType = {
    userId: string,
    auth: boolean
}
const selectUser = (state: AppRootState) => state.user
export const Company = ( props: companyPropsType) => {
    const classes = userInfoStyles()
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const {
        register,
        formState: {
            errors
        },
        handleSubmit
    } = useForm<userInfoType>()
    const navigate = useNavigate()
    const onSubmit = (data: userInfoType) => {
        console.log("props",props.userId, data)
        dispatch( setDataUserTC(props.userId ,data, props.auth) )
    }
    // useEffect(() => {
    //     if(user.user?.completeRegisterData.secondLevel){
    //         navigate("/greeting")
    //     }
    // }, [user.user?.completeRegisterData.secondLevel])
    return (
        <>
            <FlexBox as={"form"} direction="column" height="100%" gap={24} onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Название компании</div>
                    <input {...register('companyName')} className={classes.select} type="text" placeholder="" />
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Отрасль</div>
                    <select {...register("kind")} className={classes.select} name="industry" >
                        <option value="Стоматология">Стоматология</option>
                        <option value="Красота и тело">Красота и тело</option>
                        <option value="Ремонт">Ремонт</option>
                        <option value="Обслуживание">Обслуживание</option>
                        <option value="Не выбрано">Не выбрано</option>
                    </select>
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Ваша роль</div>
                    <select {...register('role')} className={classes.select} name="role" >
                        <option value="Работник">Работник</option>
                        <option value="Руководитель">Руководитель</option>
                        <option value="Основатель">Основатель</option>
                        <option value="Не выбрано">Не выбрано</option>
                    </select>
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Количество пользователей для ведения в CRM?</div>
                    <input {...register("userCRMcount")} className={classes.select} type="number" placeholder="Введите количество клиентов" />
                </div>
                <div className={classes.inputBox}>
                    <div className={classes.textItem}>Сколько пользователей будут пользоваться CRM?</div>
                    <input {...register("teamMatesCount")} className={classes.select} placeholder="Введите количество людей" type="number" />
                    <div style={{marginTop: '20px'}}>
                        <button type="submit" className={classes.btnEnd}>Завершить регистрацию</button>
                    </div>
                </div>
            </FlexBox>

        </>
    )
}