import React, { Suspense, lazy, useEffect, useId, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Registration } from './registration/Registration';
import { Login } from './registration/Login';
import Greeting from './greeting/Greeting';
import { Navbar } from './Navbar/Navbar';
import { UserInfo } from './userInfo/UserInfo';
import { authMeTC, setUserAC, stateUserType, tariffType } from './store/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState, thunkDispatchRootType } from './store/storeRedux';
import { registerAC, stateRegisterType } from './store/AuthReducer';
import { UserProfile } from './userProfiles/UserProfile';
import { ForgotPassword } from './registration/ForgotPassword';
import { Analytic } from './analytics/Analytic';
import { Tariffs } from './tarifs/Tariff';
import { ChargeOff } from './adminPage/chargeOff/ChargeOff';
import { ClientCard } from './mainPage/clientCard/ClientCard';
import { ProSettings } from './proSettings/ProSettings';
import { Header } from './header/Header';
import ProgressBar from './resultComponent/ProgressBar';
import { socket } from './apiServer/socket';
import { ModalComponents } from './modalWindows/ModalComponents';
import { Wrapper } from './styles/components/Wrapper';
import { FlexBox } from './styles/components/FlexBox';
import { CompleteRegister } from './registration/CompleteRegister';
import { RecoveryPasswordPage } from './registration/RecoveryPasswordPage';
import ProtectedRoutes from './ProtectedRoutes';
// const MainPage = React.lazy(() => import('./mainPage/MainPage'));
import { MainPage } from './mainPage/MainPage';
import TimeTables from './timeTable/timeTableComponents/TimeTables';
import Settings from './settings/Settings';
import { tokenService } from './apiServer/tokenService';
import newMessageSound from "./sounds/tone.mp3"
import { PriceList } from './proSettings/PriceList';
import { Vidzhet } from './proSettings/Vidzhet';
import { TeamMates } from './proSettings/TeamMates';
import { DeviceView, setDeviceViewAC } from './store/AppReducer';
import { WorkCategory } from './proSettings/WorkCategory';
import { AdminPage } from './adminPage/AdminPage';
import { addNewUserInvitationAC, addUserTeammateNotifInfoAC } from './store/NotificationReducer';
import { userInviteNotificationViewType } from './apiTypes/root/notofocation.type';
import { getAllTeammatesForAdminTC } from './store/UserProPlusReducer';
import TimeTablesForWeek from './timeTable/timeTableComponents/pro-plus/TimeTablesForWeek';
import { RightsType } from './apiTypes/root/userProPlus.type';
import { ClientsForProPlus } from './mainPage/ClientsForProPlus';
import { WaSending } from './proSettings/wa-sending/WA-Sending';
import { WaTimeTable } from './proSettings/wa-sending/wa-timeTable/Wa-Timetable';
import { TeammateRights } from './proSettings/TeammateRights';




const selectApp = (app: AppRootState) => app.appState
const selectUserProPlus = (state:AppRootState) => state.userProPlusState
function App() {

  const userState = useSelector<AppRootState, stateUserType>(state => state.user)
  const auth = useSelector<AppRootState, stateRegisterType>(state => state.authState)
  const appState = useSelector(selectApp)
  const userProPlusState = useSelector(selectUserProPlus)

  const dispatch:thunkDispatchRootType = useDispatch()
  const navigate = useNavigate()
  const token = tokenService.getToken()

  const location = useLocation()
  const userId = userState.user?.id as string

 
  // useEffect(() => {
    
  //   if (!userState.isAuth && token) {
  //     dispatch(authMeTC())
  //   }
  //   if(userState.isAuth && token && userState.user?.tariff === tariffType.SuperPRO) {
  //     dispatch(getAllTeammatesForAdminTC())

  //   }
  //   if( !userState.user && !userState.isAuth){
  //    if(location.pathname !== "/recovery-password/") navigate("/login")
  //   }
  // }, [userState.isAuth, userState.user?.tariff])

  // useEffect(() => {
   
  //   if (!userState.isAuth && auth.isRegister) {
     
  //     if (userState.user && !userState.user?.completeRegisterData.firstLevel && !userState.user.completeRegisterData.secondLevel) {
  //       navigate("/register/complete")
  //       return
  //     }
  //     if (userState.user && userState.user?.completeRegisterData.firstLevel && userState.user.completeRegisterData.secondLevel ) {
  //       navigate("/userInfo")
  //       return
  //     }
  //     if (userState.user && userState.user?.completeRegisterData.firstLevel && userState.user.completeRegisterData.secondLevel) {
  //       navigate("/greeting")
  //       return
  //     }
  //     if(!userState.user && auth.isRegister){
  //       navigate("/login")
  //       return
  //     }
  //   }
  //   if (userState.isAuth) {
  //     if (userState.user && !userState.user.completeRegisterData.firstLevel) {
  //       navigate("/register/complete")
  //       return
  //     }
  //     if (userState.user && userState.user.completeRegisterData.firstLevel && !userState.user.completeRegisterData.secondLevel) {
  //       navigate("/userInfo")
  //       return
  //     } else {
  //       userState.user?.isAdmin ? navigate("/sa/clients") : navigate("/main")
  //     }
      
  //   } 
  // }, [userState.isAuth, userState.user?.completeRegisterData.firstLevel, userState?.user?.completeRegisterData.secondLevel, auth.isRegister])
  
useEffect(() => {
  if(!userState.isAuth && token){
    dispatch(authMeTC())
  }
  if(userState.isAuth && userState.user?.tariff === tariffType.SuperPRO){
    dispatch(getAllTeammatesForAdminTC())
  }
},[userState.isAuth, userState.user?.tariff])

useEffect(() => {
  if(userState.isAuth){
    if(auth.isRegister && auth.registerData.firstLevel && auth.registerData.secondLevel){
     if(userState.user?.isAdmin){
      navigate("/sa/clients")
      return
     } else  {
     dispatch(authMeTC())
      //  navigate("main")
      
     } 
     
    }
    if(auth.isRegister && !auth.registerData.firstLevel && !auth.registerData.secondLevel){
      navigate("/register/complete")
      return
    }
    if(auth.isRegister && auth.registerData.firstLevel && !auth.registerData.secondLevel){
      navigate("/userInfo")
      return
    } else {
      navigate("/main")
      return
    }
    
  } else {
    
    if(auth.isRegister && !auth.registerData.firstLevel && !auth.registerData.secondLevel){
      navigate("/register/complete")
      
    }
    else if(auth.isRegister && auth.registerData.firstLevel && !auth.registerData.secondLevel){
      navigate("/userInfo")
    }
    else if(auth.isRegister && auth.registerData.firstLevel && auth.registerData.secondLevel && !auth.registerData.completeUserInfo){
      navigate("/greeting")
    } else {
     if(location.pathname !== '/recovery-password') navigate("/login")
    }
  }
},[userState.isAuth, auth.isRegister,auth.registerData.firstLevel,auth.registerData.secondLevel])

useEffect(() => {
  socket.on("USER-TEAMMATE", (data) => {
    dispatch(addNewUserInvitationAC(data))
  
  })
  socket.on("USER-TEAMMATE-INFORM-REQ",(data) => {
    dispatch(addUserTeammateNotifInfoAC(data))
    dispatch(getAllTeammatesForAdminTC())
  })

  return () => {
    socket.off("USER-TEAMMATE")
    socket.off("USER-TEAMMATE-INFORM-REQ")
  }
},[])

  useEffect(() => {
   if(userState.isAuth){
    socket.connect();
   }  
    
    return () => {
      socket.disconnect();
     
     
    };
  }, [userState.isAuth]);
  
 



useEffect(() => {
  if(window.innerWidth <= 760){
    dispatch(setDeviceViewAC(DeviceView.MOBILE))
  }
},[window.innerWidth])



  return (

    <Suspense fallback = {<ProgressBar/>}>
      
      <Wrapper>
        <Navbar id={userId} />
        <FlexBox className='content' height='100%' justify='start' padding='25px 25px 12px 25px' width='100%' direction='column'>
          {userState.isAuth && userState?.user?.completeRegisterData.firstLevel && userState?.user?.completeRegisterData.secondLevel && <Header />}
          {
            appState.isLoading && <ProgressBar />
          }
          <ModalComponents />
          <Routes>
            {!userState.user?.completeRegisterData.firstLevel && (auth.isRegister || userState.isAuth) && <Route path='/register/complete' element={<CompleteRegister />}></Route>}

            <Route 
             element={<ProtectedRoutes />} 
            >
              
              <Route path='/' element={userState.isAuth ? <Navigate to={"/main"} /> : <Navigate to={"/login"}/>}></Route>
              <Route path='/main' element={userState.user?.tariff === tariffType.SuperPRO ? <ClientsForProPlus  userId={userState.user.id}/> : <MainPage  userId={userState.user?.id as string} />} />
               <Route path='/timeTable' element={ userState.user?.tariff === tariffType.SuperPRO ? <TimeTablesForWeek /> : <TimeTables/>}></Route>
              <Route path='timeTable/settings' element={<Settings />}></Route>
              <Route path='/userProfile' element={<UserProfile />}></Route>
              <Route path='/analytic' element={<Analytic />}></Route>
              <Route path='/sa/clients' element={<AdminPage />}></Route> 
              {/* <Route path='/payments' element={<Payments />}></Route> */}
              <Route path='/tariffs' element={<Tariffs />}></Route>
              <Route path='/chargeoff' element={<ChargeOff />}></Route>
              <Route path="/client/:id" element={<ClientCard />}></Route>
              <Route path='/proSettings/' element={<ProSettings  />}>
                <Route  index element={<Navigate to={'priceList'}/>}></Route>
                <Route  path='workcategory' element={<WorkCategory />}></Route>
                <Route  path='priceList' element={<PriceList />}></Route>
                <Route  path='WA-sending' element={<WaSending />}>
                  <Route  index element = {<Navigate to={'timeTable'}/>}></Route>
                  <Route  path='timeTable' element = {<WaTimeTable/>}></Route>
                </Route>
                <Route  path='vidzhet' element={<Vidzhet/>}></Route>
                <Route  path='team' element={<TeamMates/>}>
                </Route>
              </Route>
              <Route path='rights/:teammateId' element = {<TeammateRights/>}></Route>

            </Route>

            <Route path='/' element={<Login />} ></Route>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Registration />}></Route>
            <Route path='/forgot' element={<ForgotPassword />}></Route>
            <Route path='/recovery-password' element={<RecoveryPasswordPage />}></Route>
            {/* {(auth.isRegister || userState.isAuth) && userState.user?.completeRegisterData.firstLevel && <></>} */}
            <Route path='/userInfo' element={<UserInfo auth = {userState.isAuth} userId={auth.userId || userId} />} ></Route>
            <Route path='/greeting' element={<Greeting />}></Route>
          </Routes>
        </FlexBox>

      </Wrapper>
    </Suspense>



  );
}

export default App;
