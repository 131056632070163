import axios from "axios"
import { tokenService } from "./tokenService"
import { clientBalanceFormType } from "../apiTypes/formTypes/clientBalanceForm.type"
import { workClientRequestType } from "../apiTypes/formTypes/createWorkClient.type"
import { clientCardNotesType, clientCardType, queueClientCardType, workClientType } from "../apiTypes/root/clientPro.type"
import { clientFormType } from "../apiTypes/formTypes/clientFormType"
import { apiAuth } from "./apiAuth"



const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/client-pro`,
    withCredentials: true,
   
})


instance.interceptors.request.use(
    (config) => {
        const token = tokenService.getToken()
        if(token) config!.headers!.Authorization = token
        return config
        
    },
    (error) => Promise.reject(error)
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config } = error;
      if (error.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const res = await apiAuth.refreshToken();
          tokenService.saveToken(res.data.accessToken);
          instance.defaults.headers.common.Authorization =
            tokenService.getToken();
          return instance(config);
        } catch (error) {
          tokenService.removeToken();
        }
      } else {
        // tokenService.removeToken();
      }
      return Promise.reject(error);
    }
  );
export const apiClientPro = {
    createBalance(data:clientBalanceFormType){
        return instance.post(`/wallet`,data)
    },
    createWork(data: workClientRequestType,id: string){
        return instance.post(`/work/${id}`,data)
    },
    getWorks(clientId: string){
        return instance.get<Array<workClientType>>(`/work/${clientId}`)
    },
    getById(id: string){
        return instance.get<clientCardType>(`/${id}`)
    },
    addPhotoClient(clientId: string, fileName: string, file: File, contentType: string){
        return instance.post(`/work-photo/${clientId}/${fileName}`, file, {
            headers: {
                "Content-Type": contentType
            }
        })
    },
    createComment(clientId: string, comment: string){
        return instance.post(`/comment/${clientId}`,{comment})
    },
    getNotesForClientCard(clientId: string, page: number, pageSize: number){
        return instance.get<clientCardNotesType>(`/notes/${clientId}`, {
            params: {
                page, pageSize
            }
        })
    },
   
    getQueueForClientCard(clientId: string, page: number, pageSize: number){
        return instance.get<queueClientCardType>(`/queue/${clientId}`, {params: {page,pageSize}})
    },
    editClient(dto: clientFormType, clientId: string){
        return instance.put(`client/edit/${clientId}`,dto)
    },
    changeAvatar(file:File,clientId: string){
        const fd = new FormData()
        fd.append("clientAvatar",file)
        return instance.put(`/avatar/${clientId}`,fd)
    }
}