import sprite from "../images/sprite.svg";
import { NoteBox } from "../styles/components/NoteBox";
import { NoteContent } from "../styles/components/NoteContent";
import { NoteInfo } from "../styles/components/NoteInfo";
import { NoteMedia } from "../styles/components/NoteMedia";
import { Avatar } from "../styles/components/Avatar";
import { TextContainer } from "../styles/components/TextContainer";
import { notesStateType } from "../store/NotesReducer";
import { SuperButton } from "../styles/components/SuperButton";
import { Icon } from "../styles/components/Icon";
import { FlexBox } from "../styles/components/FlexBox";
import { Box } from "../styles/components/Box";
import { TextField } from "../styles/components/TextField";
import { Text } from "../styles/components/Text";
import { useDispatch } from "react-redux";
import { thunkDispatchRootType } from "../store/storeRedux";
import { editNoteWindowAC, showRmNoteWindowAC } from "../store/ModalWindowsReducer";
import theMan from "../assets/pictures/theMan.png";
import { useState } from "react";
import { noteHelper } from "../utils/note/note.helper";
import { tariffType } from "../store/UserReducer";

type propsType = {
  el: notesStateType;
  tariff: tariffType
};
export const NoteMobile = (props: propsType) => {
  const [over, setOver] = useState<boolean>(false);
  
  const dispatch: thunkDispatchRootType = useDispatch();
  const { el } = props;
  const editNote = () => {
    if(props.tariff !== tariffType.FREE){
        dispatch(editNoteWindowAC({ value: true, note: el }));
    }
  }
  return (
    <NoteBox
      isLast
      over={over}
      device="mobile"
      onDoubleClick={editNote}
      onClick={() => setOver((prev) => !prev)}
      duration={el.duration}
      time={el.time}
      key={el.id}
    >
      <SuperButton
        onClick={(e) => {
          e.stopPropagation();
          dispatch(showRmNoteWindowAC(true, el.id));
        }}
        className="removeMobile"
        varinat="removeNote"
      >
        <Icon svgID="removeNote" width={24} height={24} />
      </SuperButton>
      <FlexBox
        direction="row"
        height="100%"
        align="center"
        padding="10px"
        gap={8}
        width="100%"
      >
        <Avatar className="avaMobile" size="adaptive" src={el.clientAvatar} />
        <FlexBox
          padding="0 0 0 10px"
          className="mobContent"
          direction="row"
          gap={5}
          width="100%"
        >
          <FlexBox className="mobileNoteBox" justify="space-between" direction="row" width="100%">
            <FlexBox gap={5} justify="center" align="center" direction="column" >
              <FlexBox direction="row" gap={3}>
                <Text font="Inter" size={12} color="#fff">
                  {el.surname}
                </Text>
                <Text font="Inter" size={12} color="#fff">
                  {el.name}
                </Text>
                <Text font="Inter" size={12} color="#fff">
                  {el.patronymic}
                </Text>
              </FlexBox>
              <FlexBox direction="row">
                <Text className="phone" font="mont" color="#fff" size={12}>
                  {el.phone}
                </Text>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" justify="space-between">
              <FlexBox
                align="center"
                height="100%"
                marginTop="auto"
                direction="row"
              >
                <FlexBox className="mobileNoteIcons" align="center"  direction="column">
                  <FlexBox direction="row">
                    <Icon
                      transform="scale(0.6)"
                      stroke="#fff"
                      fill="none"
                      svgID={noteHelper.getSvgId(el.status)}
                      width={22}
                      height={22}
                    />
                  </FlexBox>
                  <FlexBox gap={5}  direction="row">
                    <SuperButton varinat="fake">
                      <Icon
                        transform="scale(1.2)"
                        svgID="chat"
                        width={10}
                        height={10}
                      />
                    </SuperButton>
                    <SuperButton varinat="fake">
                      <Icon
                        transform="scale(1.2)"
                        svgID="envelope"
                        width={10}
                        height={10}
                      />
                    </SuperButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <textarea cols={20} rows={4} className="textareaMob" value={el.comments} />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </NoteBox>
  );
};
