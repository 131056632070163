import { netSessionType } from "../apiTypes/root/networkSession"


export type AppReducerAT = setIsLoadingAT | setEnableSwitchAT | setIsNotificationAT | setErrorMessageTeamReqAT | setNetSessionAT | setSuccesMessageSetting
    | setSuccesMessagePassRecoveryAT | setSuccesRestorePassMessageAT | setDeviceViewAT | setSuccessMessageChangeStatusAT | setQueueRescheduleMessageAT
    | setSuccesMessageClientBalanceAT | setSuccesMessageClientAvaAT | setSuccesMessageClientRightsAT | setSuccesMessageSettingsRightsAT
type setIsLoadingAT = {
    type: "APP/ISLOADING",
    isLoading: boolean
}
type setEnableSwitchAT = {
    type: "APP/ENABLE-SWITCH"
    enableSwitch: boolean
}
type setIsNotificationAT = {
    type: "APP/NEW-NOTIFICATION",
    isNotification: boolean
}
type setErrorMessageTeamReqAT = {
    type: "APP/SET-ERROR-TEAM-REQ",
    errorMessageTeamRequest: string
}

type setNetSessionAT = {
    type: "APP/SET-NET-SESSIONS",
    sessions: netSessionType[]
}
type setSuccesMessageSetting = {
    type: "APP/SET-SUCCESS-MESSAGE-SETTINGS",
    message: string
}
type setSuccesMessagePassRecoveryAT = {
    type: "APP/SET-SUCCESS-MESSAGE-PSWD-RECOVERY",
    message: string
}
type setSuccesRestorePassMessageAT = {
    type: "APP/SET-SUCCESS-RESTORE-PSWD-MESSAGE",
    message: string
}
type setDeviceViewAT = {
    type: "APP/SET-DEVICE-VIEW",
    device: DeviceView
}

type setSuccessMessageChangeStatusAT = {
    type: "APP/SET-NOTE-STATUS-MESSAGE",
    message: string
}
type setQueueRescheduleMessageAT = {
    type: "APP/SET-QUEUE-RESCHEDULEMESSAGE",
    message: string
}

type setSuccesMessageClientBalanceAT = {
    type: "APP/SET-SUCCESS-MESSAGE-CLIENT-BALANCE",
    message: string
}
type setSuccesMessageClientAvaAT = {
    type: "APP/SET-SUCCESS-MESSAGE-CLIENT-AVA",
    message: string
}
type setSuccesMessageClientRightsAT = {
    type: "APP/SET-SUCCESS-MESSAGE-CLIENT-RIGHTS",
    message: string
}
type setSuccesMessageSettingsRightsAT = {
    type: "APP/SET-SUCCESS-MESSAGE-SETTINGS-RIGHTS",
    message: string
}



export const AppReducer = (state: AppReducerStateType = initState, action: AppReducerAT): AppReducerStateType => {
    switch (action.type) {
        case "APP/ISLOADING":
            return { ...state, isLoading: action.isLoading }
        case "APP/ENABLE-SWITCH":
            return { ...state, enableSwitch: action.enableSwitch }
        case "APP/NEW-NOTIFICATION":
            return { ...state, isNotification: action.isNotification }
        case "APP/SET-ERROR-TEAM-REQ":
            return { ...state, errorMessageTeamRequest: action.errorMessageTeamRequest }
        case "APP/SET-NET-SESSIONS":
            return { ...state, sessions: action.sessions.map(el => ({ ...el, device: el.device === "desktop" ? "ПК" : "Моб.телефон" })) }
        case "APP/SET-SUCCESS-MESSAGE-SETTINGS":
            return { ...state, successMessageSetting: action.message }
        case "APP/SET-SUCCESS-MESSAGE-PSWD-RECOVERY":
            return {...state, successMessagePassRecovery: action.message}
        case "APP/SET-SUCCESS-RESTORE-PSWD-MESSAGE":
            return {...state, succesRestorePassMessage: action.message}
        case "APP/SET-DEVICE-VIEW":
            return {...state, deviceView: action.device}
        case "APP/SET-NOTE-STATUS-MESSAGE":
            return {...state,noteChangeStatusMessage: action.message}
        case "APP/SET-QUEUE-RESCHEDULEMESSAGE":
            return {...state, queueRescheduleMessage: action.message}
        case "APP/SET-SUCCESS-MESSAGE-CLIENT-BALANCE":
            return {...state,successMessageClientBalance: action.message}
        case "APP/SET-SUCCESS-MESSAGE-CLIENT-AVA":
            return {...state, successMessageAvatarChange: action.message}
        case "APP/SET-SUCCESS-MESSAGE-CLIENT-RIGHTS":
            return {...state, successMessageClientRights: action.message}
        case "APP/SET-SUCCESS-MESSAGE-SETTINGS-RIGHTS":
            return {...state, successMessageSettingsRights: action.message}
        default:
            return state
    }
}


export const setIsLoadingAC = (isLoading: boolean): setIsLoadingAT => {
    return { type: "APP/ISLOADING", isLoading }
}
export const setNetSessionsAC = (sessions: netSessionType[]): setNetSessionAT => {
    return { type: "APP/SET-NET-SESSIONS", sessions }
}
export const setEnableSwitchAC = (enableSwitch: boolean): setEnableSwitchAT => {
    return { type: "APP/ENABLE-SWITCH", enableSwitch }
}
export const setIsNotificationAC = (isNotification: boolean): setIsNotificationAT => {
    return { type: "APP/NEW-NOTIFICATION", isNotification }
}
export const setErrorMessageTeamReqAC = (msg: string): setErrorMessageTeamReqAT => {
    return { type: "APP/SET-ERROR-TEAM-REQ", errorMessageTeamRequest: msg }
}
export const setSuccesMessageSettingAC = (message: string): setSuccesMessageSetting => {
    return { type: "APP/SET-SUCCESS-MESSAGE-SETTINGS", message }
}
export const setSuccesMessagePassRecoveryAC = (message: string): setSuccesMessagePassRecoveryAT => {
    return {type: "APP/SET-SUCCESS-MESSAGE-PSWD-RECOVERY", message}
}
export const setSuccesRestorePassMessageAC = (message: string): setSuccesRestorePassMessageAT => {
    return {type: "APP/SET-SUCCESS-RESTORE-PSWD-MESSAGE", message}
}

export const setDeviceViewAC = (deviceView: DeviceView):setDeviceViewAT => {
    return {type: "APP/SET-DEVICE-VIEW",device:deviceView}
}

export const setSuccessMessageChangeStatusAC = (message: string): setSuccessMessageChangeStatusAT => {
    return {type: "APP/SET-NOTE-STATUS-MESSAGE", message}
}

export const setQueueRescheduleMessageAC = (message: string):setQueueRescheduleMessageAT => {
    return {type: "APP/SET-QUEUE-RESCHEDULEMESSAGE",message}
}

export const setSuccesMessageClientBalanceAC = (message: string):setSuccesMessageClientBalanceAT => {
    return {type: "APP/SET-SUCCESS-MESSAGE-CLIENT-BALANCE",message}
}
export const setSuccesMessageClientAvaAC = (message: string):setSuccesMessageClientAvaAT => {
    return {type: "APP/SET-SUCCESS-MESSAGE-CLIENT-AVA",message}
}
export const setSuccesMessageClientRightsAC = (message: string):setSuccesMessageClientRightsAT => {
    return {type: "APP/SET-SUCCESS-MESSAGE-CLIENT-RIGHTS",message}
}
export const setSuccesMessageSettingsRightsAC = (message: string):setSuccesMessageSettingsRightsAT => {
    return {type: "APP/SET-SUCCESS-MESSAGE-SETTINGS-RIGHTS",message}
}


export enum DeviceView {
    PC ="PC",
    MOBILE = "MOBILE"
}
export type AppReducerStateType = {
    isLoading: boolean,
    enableSwitch: boolean,
    isNotification: boolean,
    errorMessageTeamRequest: string,
    sessions: netSessionType[],
    successMessageSetting: string,
    successMessagePassRecovery: string,
    succesRestorePassMessage: string,
    deviceView: DeviceView,
    noteChangeStatusMessage: string,
    queueRescheduleMessage: string,
    successMessageClientBalance: string,
    successMessageAvatarChange: string,
    successMessageClientRights: string,
    successMessageSettingsRights: string
}
const initState: AppReducerStateType = {
    isLoading: false,
    enableSwitch: true,
    isNotification: false,
    sessions: [],
    errorMessageTeamRequest: "",
    successMessageSetting: "",
    successMessagePassRecovery: "", 
    succesRestorePassMessage: "",
    deviceView: DeviceView.PC,
    noteChangeStatusMessage: "",
    queueRescheduleMessage: "",
    successMessageClientBalance: "",
    successMessageAvatarChange: "",
    successMessageClientRights: "",
    successMessageSettingsRights: ""
}


