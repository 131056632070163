
import React, { useEffect } from "react";
import Avatars from '../../assets/Avatars.svg'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAC, stateUserType, tariffType } from "../store/UserReducer";
import { AppRootState } from "../store/storeRedux";
import { Icon } from "../styles/components/Icon";
import { Avatar } from "../styles/components/Avatar";
import { FlexBox } from "../styles/components/FlexBox";
import { Box } from "../styles/components/Box";
import { Text } from "../styles/components/Text";
import { MenuLink } from "../styles/components/MenuLink";
import { SuperButton } from "../styles/components/SuperButton";
import { returnTariff } from "../utils/utils";
import { HeaderButton } from "../styles/components/HeaderButton";

type menuItemType = {
    id: string, item: string, img: string, route: string, cb?: () => void
}
type propsType = {
    menuRender: () => menuItemType[],
    setBurger: (value: boolean) => void
}

const selectUser = (app: AppRootState) => app.user
export const MobileNav = (props: propsType) => {
    const userState = useSelector(selectUser)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const linkHandler = (cb?: () => void) => {
        if (cb) cb()
        props.setBurger(false)
    }
    const toTariffPage = () => {
        navigate("/tariffs")
        props.setBurger(false)
    }
    return (
        <Box className="mobileMenu" direction="column" background="rgb(99, 97, 100)" position="fixed" width="236px" borderRaduis="8px" left="0px" zIndex={35} gap={20} margin="32px 0 10px 0" height="485px" top="3.5%" >
            <FlexBox direction="column" width="100%" padding="10px" gap={10}  align="center" justify="center">
                <Link to='/userProfile'>
                    <Avatar size="large" src={userState.user?.avatarUrl} alt="" />
                </Link>

                <FlexBox direction="column" align="center" gap={5}>
                    <div>
                        <Text color="#fff" size={14} font="mont">{userState.user?.lastName} </Text>
                        <Text color="#fff" size={14} font="mont">{userState.user?.firstName}</Text>
                    </div>

                    <Text color="#fff" size={14} font="mont">Мастер по маникюру</Text>

                </FlexBox>
            </FlexBox>
            <FlexBox direction="column" gap={10} padding="10px">
                {
                    props.menuRender().map(el =>
                        <MenuLink onClick={() => linkHandler(el.cb)} as={NavLink} to={el.route} active={location.pathname === el.route ? true : false} >
                            <Icon width={32} height={32} svgID={el.img} />
                            <Text size={16} color="#fff" font="mont">{el.item}</Text>
                        </MenuLink>
                    )
                }


            </FlexBox>
            <FlexBox   style={{marginLeft:"20px"}} direction="row" minHeight="36px" height="36px" width="60%" >
                <HeaderButton style={{height: "36px"}} varinat="header" onClick={toTariffPage} >
                    <Icon width={17} height={19} svgID="proIcon" />
                    <div>
                        <Text weight={400} size={12} font="mont" color="#fff">{returnTariff(userState.user?.tariff as tariffType).split(" ").splice(0, 2).join(" ")} </Text>
                        <Text weight={700} size={14} font="mont" color="#fff">{returnTariff(userState.user?.tariff as tariffType).split(" ").splice(2, 1).join(" ")}</Text>
                    </div>

                </HeaderButton>
            </FlexBox>

        </Box>
    )
}