import styled, { css } from "styled-components";
import { theme } from "./Theme.styled";

type propsType = {
    varinat: "header" | "header-pro" | "header-superPro"
}

export const HeaderButton = styled.button<propsType>`
    
    height: 100%;
    ${props => props.varinat === "header" && css<propsType>`
        background:rgb(202, 91, 10);
        min-width: 185px;
        width: 223px; 
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-left: 0px;
        border: none;
        gap: 10px;
        &:hover {
            background: black
        };
        @media screen and (max-width: 768px) {
           &:hover {
            background: ${theme.colors.main};
           } 
    };
    `};
    ${props => props.varinat === "header-pro" && css<propsType>`
        background: #598866;
        min-width: 185px;
        width: 223px; 
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-left: 0px;
        border: none;
        gap: 10px;
        &:hover {
            background: black
        };
        @media screen and (max-width: 768px) {
           &:hover {
            background: ${theme.colors.main};
           } 
    };
    `};
    ${props => props.varinat === "header-superPro" && css<propsType>`
        background: #598866;
        min-width: 185px;
        width: 223px; 
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-left: 0px;
        border: none;
        gap: 10px;
        &:hover {
            background: black
        };
        @media screen and (max-width: 768px) {
           &:hover {
            background: ${theme.colors.main};
           } 
    };
    `};
`