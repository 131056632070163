import { useEffect, useState } from "react"
import { Box } from "../../styles/components/Box"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"

type propsType = {
    actualDate: Date,
    setActaulDate: (d:Date) => void
}

export const CurrentTimeComponent = (props: propsType) =>  {

    useEffect(() => {
        const interval = setInterval(() => {
            props.setActaulDate(new Date())
        },1000) 

        return () => {
            clearInterval(interval)
        }
    },[])
    return (
        <Box  zIndex={10} top={`calc(${ (parseInt(props.actualDate.getMinutes().toString()) / 60 * 100)}%);`} position="absolute" width="100%" height="12px" background="0">
            <FlexBox direction="row" width="100%" align="center" height="100%">
                <FlexBox border="1px solid rgba(89, 136, 102, 0.8)" direction="row" align="center" justify="center" borderRadius="25px" height="100%" width="50px">
                    <Text weight={700} size={9} font="Inter" color="rgb(68, 68, 68)">
                    {`${props.actualDate.getHours().toString().padStart(2,"0")}:${props.actualDate.getMinutes().toString().padStart(2,"0")}`}

                    </Text>
                </FlexBox>
                <FlexBox width="100%" direction="row" border="0.5px solid rgba(89, 136, 102, 0.8)" height="1px"></FlexBox>
            </FlexBox>
        </Box>
    )
}