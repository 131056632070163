import { useEffect, useId, useState } from "react"
import {  settingsRightsType } from "../../apiTypes/root/userProPlus.type"
import { CheckBox } from "../../styles/components/CheckBox"
import { FlexBox } from "../../styles/components/FlexBox"
import { Text } from "../../styles/components/Text"
import { useForm } from "react-hook-form"
import { theme } from "../../styles/components/Theme.styled"
import { AppRootState, thunkDispatchRootType } from "../../store/storeRedux"
import { useDispatch, useSelector } from "react-redux"
import { changeTimeTableRightsTC } from "../../store/UserProPlusReducer"
import { setSuccesMessageSettingsRightsAC } from "../../store/AppReducer"

type propsType = {
    payload: settingsRightsType
}

const selectAppState = (state:AppRootState) => state.appState


export const SettingsRights = (props: propsType) => {
    const dispatch:thunkDispatchRootType = useDispatch()
        const appState = useSelector(selectAppState)
    
    const initState: {id: string, field: keyof settingsRightsType, title: string,value: boolean}[] = [
        {id: useId(), field: "priceListManage" ,title:"Прайслист добавление - редактирование", value: props.payload.priceListManage},
        {id: useId(), field: "priceListManage" ,title: "Рассылка Добавить - редактировать", value: props.payload.sendingManage},
        // {id: useId(), field: "candReadTeammateNotes" ,title: "Удаление (своих)", value: props.payload.},
        {id: useId(), field: "payManage" ,title: "Оплачивать програму", value: props.payload.payManage},
        {id: useId(), field: "canEditTeammates" ,title: "Редактировать других сотрудников", value: props.payload.canEditTeammates},
    ]
    const [state, setState] = useState<Array<{id: string, field: keyof settingsRightsType, title: string,value: boolean}>>([])

    const [editMode, setEditMode] = useState<boolean>(false)
    const [initValues, setInitValues] = useState<boolean[]>([])

    const {register, handleSubmit, setValue, formState} = useForm< settingsRightsType>(
        {
            // defaultValues: {
            //     canAddAndEditOwn: props.payload.canAddAndEditOwn, 
            //     candReadTeammateNotes: props.payload.candReadTeammateNotes, 
            //     canEditPastTime: props.payload.canEditPastTime, 
            //     isAdmin: props.payload.isAdmin,
            //      id: props.payload.id, 
            //      teammateId: props.payload.teammateId
            // }
        }
        
    )
    const saveData = (data: settingsRightsType) => {
        // dispatch(changeTimeTableRightsTC(props.payload.teammateId, data))
    }
    const handler = (id: string, value: boolean, field: keyof settingsRightsType) => {
        setState(state.map(el => el.id === id ? {...el, value} : el))
        setValue(field, value)
    }
    function checkType(value: any): value is keyof(settingsRightsType){
        return Object.keys(props.payload).includes(value)
    }
    const reset = (e:any) => {
        e.preventDefault()
      setState(initState)
      }
    
    useEffect(() => {
           if(initValues.length){
               const values = state.map(el => el.value).sort((a,b) => a > b ? 1 : -1)
          if(JSON.stringify(values) === JSON.stringify(initValues.sort((a,b) => a > b ? 1 : -1))){
           setEditMode(false)
          } else {
           setEditMode(true)
          }
           }
          
       },[JSON.stringify(state), initValues.length])
      
       useEffect(() => {
           if(appState.successMessageSettingsRights){
               setEditMode(false)
               setTimeout(() => {
                   dispatch(setSuccesMessageSettingsRightsAC(""))
               },1500)
           }
       },[appState.successMessageClientRights])
       useEffect(() => {
           if(props.payload.id){
               setState(initState)
               let arr:boolean[] = []
               for(let key in props.payload){
                   if(checkType(key) && (typeof props.payload[key] === "boolean")){
                      const value =  props.payload[key] as boolean
                      arr.push(value)
                       setValue(key, value)
                   }
               }
               setInitValues(arr)
               setValue("id",props.payload.id)
           } 
           if(props.payload.teammateId) setValue("teammateId",props.payload.teammateId)
       },[props.payload.id, props.payload.teammateId, JSON.stringify(props.payload)])
    return(
        <FlexBox onSubmit={handleSubmit(saveData)} as={"form"} direction="column" width="100%" height="100%">
            <FlexBox background="#444" direction="column" justify="center" align="center" width="100%" height="37px" borderRadius="8px">
                <Text color="#fff" size={14} font="mont">Расписание</Text>
            </FlexBox>
            
                {
                    state.map(el => 
                        <FlexBox marginTop="5px" padding="0 10px" height="36px" background={theme.colors.colorForm} borderRadius="8px" align="center" gap={10} key={el.id} direction="row">
                        <CheckBox
                        variant="primary"
                        value={el.value}
                        render={() => (
                          <input
                            checked={el.value}
                            onChange={(e) => {e.preventDefault(); handler(el.id, e.currentTarget.checked, el.field)}}
                            type="checkbox"
                            
                          />
                        )}
                      />
                      <Text size={14} font="mont" color="#000">{el.title}</Text>
                       </FlexBox>
                    )
                }
               {
                editMode && <FlexBox gap={10} height="36px" direction="row" width="100%">
           
                <button onClick={reset} style={{width: "100%", background:0, border: "none", fontSize: 12, fontWeight: 600}}>Сбросить</button>
                <button style={{width: "100%", background:0, border: "none",fontSize: 12,fontWeight: 600,textShadow: "0 2px rgba(0, 0, 0, 0.25)", color: "#5d5ffe"}}>Сохранить</button>
               </FlexBox>
            } 
                      <Text size={12} color="green" font="mont">{appState.successMessageSettingsRights}</Text>

        </FlexBox>
    )
}