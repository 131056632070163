import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { selectedMasterType, selectMasterAC } from "../store/CommonReducers"
import { AppRootState } from "../store/storeRedux"
import { getAllTeammatesForAdminTC } from "../store/UserProPlusReducer"
import { tariffType } from "../store/UserReducer"
import { Avatar } from "../styles/components/Avatar"
import sprite from "../images/sprite.svg"
import { teammateProSettingsType } from "../apiTypes/root/userProPlus.type"




type selectType = {
    active: boolean,
    height?: number
}

type textType = {
    list?:boolean
}
type customSelectType = {
    selectedMaster: selectedMasterType | 'all'
    teammates: teammateProSettingsType[]
    setSelectedMaster: (master: selectedMasterType | 'all') => void
   height?:number
}
const selectUser = (app: AppRootState) => app.user
const selectCommonState = (app:AppRootState) => app.commonReducerState
const selectUserProPlus = (state: AppRootState) => state.userProPlusState

export const SelectForFilterClients = (props: customSelectType) => {
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const commonState = useSelector(selectCommonState)
    const handler = (e:React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault()
       setActive(!active)
    }
    const userProPlusState = useSelector(selectUserProPlus)
    const {teammates} = userProPlusState
    
   const selectTeammate = (master: selectedMasterType | 'all') => {
    props.setSelectedMaster(master)
    setActive(false)
   }
    
   
   useEffect(() => {
    props.setSelectedMaster('all')
   },[])
    return (
        <SelectContainer>
            <Select height={props.height}  onClick={handler} active={active}>
                <ContentBox>
                   {
                   props.selectedMaster !== 'all' && 
                   <Avatar size="selectAvatar" src={props.selectedMaster.avatarUrl}/> }
                   {
                    props.selectedMaster === 'all'
                    ? <Text>{'Выбрать всех'}</Text>
                    : <Text>{props.selectedMaster.masterName}</Text>
                   }  
                </ContentBox>
                <svg>
                    <use xlinkHref={`${sprite}#row`}></use>
                </svg>
            </Select>
            {
                active && <OptionContainer>
                    {
                        props.teammates.map(el => 
                            
                            <OptionItem onClick={() => selectTeammate({masterID :el.user.userId, masterName:el.user.firstName,avatarUrl:el.user.avatarUrl})}>
                                <div style={{ marginLeft: "14px", display: "flex",alignItems: "center",justifyContent: "center"}}><Avatar src={el.user.avatarUrl} size="selectAvatar"/></div>
                                <Text list>{el.user.firstName}</Text>
                            </OptionItem>
                            )
                    }
                   <OptionItem  onClick={() => selectTeammate('all')}>
                                <Text className="listText" list>{'Выбрать всех'}</Text>
                 </OptionItem>
                </OptionContainer>

            }
        </SelectContainer>

    )
}

const Select = styled.button<selectType>`
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 8px;
width: 140px;
height: ${props => props.height ? props.height + "px" :  "50px"} ;
display: flex;
gap: 10px;
align-items: center;
padding-right: 15px;
svg {
    width: 6px;
    height: 3px;
    margin-left: auto;

    ${props => props.active && css<selectType>`
        transform: rotate(180deg);
    `}
};
&:hover {
    background: #444444;
    color: #fff
};
&:hover svg {
    fill: #fff
};
@media screen and (max-width: 768px) {
     width: 110px;
     height: 36px;
     gap: 5px;
     margin-left:3px;
     font-size: 12px !important
 };

`
const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`
const ContentBox = styled.div`
    display: flex;
    margin-left: 6px;
    align-items: center;
    text-align: left;
    justify-content: center;
    width: 100%;
    gap: 10px;
    @media screen and (max-width: 768px) {
     gap: 2px;
     font-size: 12px;
 };
    
`
const OptionContainer = styled.ul`
    position: absolute;
    

    z-index: 5;
    background: #fff;
    top: 53px;
    width: 140px;
    max-height: 144px;
    overflow: auto;
    border-radius: 8px;
    z-index: 16;
    &::-webkit-scrollbar{
        width:7px;
    };
    &::-webkit-scrollbar-thumb{
        background: #262626;
        opacity: 0.6;
        border: 3px solid #fff;
        border-radius: 6px;
    }
    @media screen and (max-width: 768px) {
     font-size: 12px !important;
 };
`
const OptionItem = styled.li`
  .listText {
    

            width: 100% !important;
            @media screen and (max-width: 768px) {
     font-size: 11px !important;
 };
        }
    display: flex;
    height: 36px;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid rgb(0,0,0,0.2);
    &:first-child{
        border-radius: 8px 8px 0px 0px;
    };
    &:last-child {
        border-radius: 0px 0px 8px 8px;
        border-bottom: none;
    };
    &:hover{
        border: 1px solid rgb(0,0,0,0.8);
        padding: 0;
        cursor: pointer;
    }
   
`
const Text = styled.div<textType>`
        font-weight: 600;
        font-size: 13px;
        font-family: "mont";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        ${props => props.list && css<textType>`
            width: 55%;
        `}
        @media screen and (max-width: 768px) {
     font-size: 11px !important;
 };
`
